import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import './Hierarchy.css';
import AssetAppPic from '../images/AssetApp.ico';

export class SignIn extends Component {
     static displayName = SignIn.name;  
   
    render() {
  
        let email = this.props.user.account.userName;
        let emailID = email.split("@")[0];

        return (
            <div>
                <Grid container={true} justifyContent='center' alignItems='center' spacing={1}>
                    <Grid item>
                        <p className="header">Welcome to Asset App</p>

                    </Grid>
                </Grid>
                {/*  <div class="container1">
                    <img style={{
                        width: "35%",
                        height: "35%",
                        marginBottom: "8px",
                        marginTop: "28px",

                    }} src={AssetAppPic} alt="logo" className="photo" />
                    <div class="centered">AssetApp</div>
                </div>
           
           
                <Grid container={true} justifyContent='center' alignItems='center' spacing={1}>               
                    <Grid item>
                        <p style={{
                           
                            marginTop: "58px",

                        }}>User Name - {this.props.user.account.name}</p>
                        <p>User ID - {emailID}</p>
                        <p>Role - {this.props.user.account.idTokenClaims.roles}</p>
                    </Grid>
                </Grid>    
                
                */}
          
            </div>
        );
    }
}

