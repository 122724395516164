// Azure SDK client libraries
import { ComputerVisionClient } from '@azure/cognitiveservices-computervision';
import { ApiKeyCredentials } from '@azure/ms-rest-js';

// Authentication requirements
//const key = process.env.REACT_APP_COMPUTERVISIONKEY;
//const endpoint = process.env.REACT_APP_COMPUTERVISIONENDPOINT;

const key = "57a9617ba22b41b196720d368076e1e3";
const endpoint = "https://wt00-cv-001-p.cognitiveservices.azure.com/";

// Cognitive service features
const visualFeatures = [
    "ImageType",
    "Faces",
    "Adult",
    "Categories",
    "Color",
    "Tags",
    "Description",
    "Objects",
    "Brands"
];

export const isConfigured = () => {
    const result = !!(key && endpoint && (key.length > 0) && (endpoint.length > 0));
    return result;
}

// Computer Vision detected Printed Text
const includesText = async (tags) => {
    return tags.filter((el) => {
        return el.name.toLowerCase() === "text";
    });
}
// Computer Vision detected Handwriting
const includesHandwriting = async (tags) => {
    return tags.filter((el) => {
        return el.name.toLowerCase() === "handwriting";
    });
}
// Wait for text detection to succeed
const wait = (timeout) => {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}

// Analyze Image from URL
export const computerVision = async (blob) => {

    // authenticate to Azure service
    const computerVisionClient = new ComputerVisionClient(
        new ApiKeyCredentials({
            inHeader: {
                'Ocp-Apim-Subscription-Key': key,
                'content-type': "application/octet-stream",
            }
        }), endpoint);
    // analyze image
    const analysis = await computerVisionClient.analyzeImageInStream(blob, { visualFeatures });

    // text detected - what does it say and whcls\ere is it
    if (await includesText(analysis.tags) || await includesHandwriting(analysis.tags)) {
        analysis.text = await readTextFromBlob(computerVisionClient, blob);
    }

    // all information about image
    return { ...analysis };
}
// analyze text in image
const readTextFromBlob = async (client, blob) => {

    let result = await client.readInStream(blob);
    let operationID = result.operationLocation.split('/').slice(-1)[0];

    // Wait for read recognition to complete
    // result.status is initially undefined, since it's the result of read
    const start = Date.now();

    while (result.status !== "succeeded") {
        await wait(500);
        result = await client.getReadResult(operationID);
    }

    // Return the first page of result.
    // Replace[0] with the desired page if this is a multi-page file such as .pdf or.tiff.
    return result.analyzeResult;
}