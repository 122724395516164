import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import QrReader from 'react-qr-reader'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid } from '@material-ui/core';
import { getSerialNoForAutocomplete } from '../services/LibraryService';
import './Hierarchy.css';
import Tooltip from '@material-ui/core/Tooltip';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export class QRScannerDialog extends Component {
  
    constructor(props) {
        super(props);
        console.log("Props in Input :", props);
        this.handleChange = this.handleChange.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
       
        
        this.state = {
            result: '',
            open: false,

            SerialNumberData: [],

            currentAsset: {
                isLoading: true,
                serienr: '',
            }
        };
    }


    handleToggle = () => {
       
        this.setState({
            open: !this.state.open,
           
        })
    }

    handleScan = data => {
        if (data) {
            this.setState({
                result: data
            })
        }
    }

    handleError = err => {
        console.error(err)
    }

    submitHandler(evt) {
        evt.preventDefault();
        // pass the input field value to the event handler - passed as a prop by the parent     
        this.props.handlerFromParant(this.state.result);
        this.setState({
            result: '',        
        });
    }

   handleChange(event) {
        this.setState({

            result: event.target.value
        });     
    }

    // for autocomplete onchange - to capture user typed input and load SerialNumberData from API
    onChangeInputSerialNo = value => {

        const caseInsensitiveValue = value.toUpperCase()
        getSerialNoForAutocomplete(caseInsensitiveValue).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            console.log(responseData);
                            this.setState({
                                isLoading: false,
                                SerialNumberData: responseData

                            })
                        });
                }

            })
    }

    //To set selected item from autocomplete dropdown to 'serienr'
    onChangeCaptureAutComSerienr = (event, value) => {

        this.setState(function (prevState) {
            return {
                currentAsset: {
                    ...prevState.currentAsset,
                    serienr: value 
                }
            };
        }, () => {


        });
        this.setState({
            result: value
        });      
    }

    render() {
        const { open } = this.state

        const textStyle = {
            justifyContent: 'center',
            fontFamily: "Arial",
            fontSize: 15,
            "text-align": "center",
        }

        return (
            <div>
                <Tooltip title={<span style={{ fontSize: "11px", color: "white" }}>QR Scanner</span>} placement="top" arrow='true'>
                    <Button variant="outlined" disabled={this.props.disabled} style={{
                        backgroundColor: "#79797F",
                        height: 36,
                        borderColor: 'lightgrey'

                    }}  onClick={this.handleToggle}>
                        
                    <FontAwesomeIcon
                        style={{                      
                                color: 'white',                          
                                width: 18,
                                height: 18,
                        }}
                            icon={faQrcode} />
                    
                    </Button>
                </Tooltip>

                <Dialog fullWidth open={open} maxWidth="xs" onClose={this.handleToggle}>                 
                    <DialogTitle style={{
                        backgroundColor: "#2da55d"
                    }} id="customized-dialog-title" onClose={this.handleToggle}>
                        <span style={{                       
                            fontFamily: "Arial",
                            fontSize: 13,
                            color: "white"
                        }}> Scan Serial Number </span>
                    </DialogTitle>

                    <DialogContent>
                        <div>
                            <div>
                                <QrReader
                                    delay={100}
                                    //showViewFinder={true}
                                    onError={this.handleError}
                                    onScan={this.handleScan}
                                    showViewFinder={false}
                                />
                            </div>
                            <form onSubmit={this.submitHandler}>
                                <br />
                                <p style={textStyle}>                               
                                    <Grid container={true} justifyContent='center' alignItems='center' spacing={2}>

                                    <Grid Item>
                                        <Autocomplete 
                                            value={this.state.result}
                                            options={this.state.SerialNumberData}
                                            autoHighlight={true}
                                            renderOption={(option) => (
                                                <Typography class="typography">{option}</Typography>
                                            )}
                                            getOptionLabel={option => option}
                                            onChange={this.onChangeCaptureAutComSerienr}
                                            style={{
                                                width: 210,
                                            }}
                                            renderInput={params => (
                                                <TextField {...params} onChange={e => this.onChangeInputSerialNo(e.target.value)} label="Serial Number" variant={"outlined"}
                                                    size="small" InputLabelProps={{ style: { fontSize: 14, color: '#33333C', fontFamily: "Arial" }, shrink: true }} fullWidth />
                                            )}
                                        />
                                    </Grid>
                                    </Grid>
                                    <br/>

                                    <Button
                                        style={{
                                            textTransform: 'capitalize',
                                            width: 70,
                                            height: 30,
                                            fontSize: 13,
                                            fontFamily: "Arial",
                                            color: 'white',
                                            backgroundColor: '#2da55d',
                                            borderColor: 'lightgrey',
                                            marginLeft: '5px',
                                            marginBottom: '0px'
                                        }}
                                        type="submit"
                                        className="badge badge-secondary"
                                        onClick={this.handleToggle}
                                    >
                                        Submit
                                  </Button>

                                </p>
                                <br />
                            </form>
                        </div>
                    </DialogContent>
                   
                </Dialog>
            </div>
        );
    }

}