import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { getRedress } from '../services/LibraryService';
import { createTheme } from "@material-ui/core/styles";
import dateFormat from 'dateformat';


// Styling for autocomplete box color
const theme = createTheme({});

theme.overrides = {
    MuiOutlinedInput: {
        root: {
            "&$focused $notchedOutline": {
                borderColor: 'grey !important'
            }
        }
    }
};

// Create the function to add javascript file
export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
}

export class LabelPrinter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentAsset: {
                serialNo: null,
                itemNo: "",
                jobID: "",
                itemDescription: "",
                incidentReportNumber: "",
                modificationsCarriedOut: "",
                checkedBy: "",
                currentDate: "",
                redressedBy: "",
                redressDate: "",
                level: "",
                isComplete: "",
                typeCode: "",
                modificationsCarriedOut: "",
                comment: "",
                typeCode: "",
                concatenateSerialItemNo: "",
                concatenateRedress: ""
            }
        };

    }

    async componentDidMount() {
        this.searchSerienr()
    }

    async searchSerienr() {
        const search = this.props.location.search; // returns the URL query String
        const params = new URLSearchParams(search);
        const datafromurl = params.get("serialNo")

        await getRedress(datafromurl).then(
        (response) => {
            if (response.ok) {
                response.json()
                    .then((responseData) => {
                        this.setState({
                            isLoading: false,
                            currentAsset: responseData
                        })

                        let concatenateSerialItemNo = this.state.currentAsset.serialNo + "/" + this.state.currentAsset.itemNo
                        let email = this.props.user.account.userName;
                        let UserName = email.split("@")[0];
                        let checkedBy = UserName
                        let getCurrentDate = new Date().toLocaleString() + "";
                        let currentDate = dateFormat(getCurrentDate, "m/d/yyyy");
                        let redressDateFormat = this.state.currentAsset.redressDate && this.state.currentAsset.redressDate.substring(0, 10);
                        let redressDate = dateFormat(redressDateFormat, "m/d/yyyy");
                        let level = "Level " + this.state.currentAsset.level;
                        let modificationsCarriedOut;
                        if (this.state.currentAsset.modificationsCarriedOut == true) {
                            modificationsCarriedOut = "Yes"
                        }
                        else {
                            modificationsCarriedOut = "No"
                        }
                        this.setState(function (prevState) {
                            return {
                                currentAsset: {
                                    ...prevState.currentAsset,
                                    checkedBy: checkedBy,
                                    currentDate: currentDate,
                                    redressDate: redressDate,
                                    level: level,
                                    modificationsCarriedOut: modificationsCarriedOut

                                }
                            };
                        });
                        let concatenateItemNOSerialNo = this.state.currentAsset.itemNo + "/" + this.state.currentAsset.serialNo
                        let concatenateRedress = this.state.currentAsset.redressDate + ", " + level + ", " + this.state.currentAsset.redressedBy
                        this.setState(function (prevState) {
                            return {
                                currentAsset: {
                                    ...prevState.currentAsset,
                                    concatenateRedress: concatenateRedress,
                                    concatenateSerialItemNo: concatenateItemNOSerialNo
                                }
                            };
                        });
                      
                    });
            }
        })
        .catch((err) => {
            this.setState({ isLoading: false, errorData: err.message });
        });
}


    render() {
     
        return (
            <div>
                <Grid container={true} justifyContent='center' alignItems='center'>
                    <Grid item>
                        <p className="labelHeader" style={{ fontFamily: "Arial" }}>Label Print</p>
                    </Grid>
                </Grid>
                <br />

                <div class="top">

                    <div class="left">
                        <div id="addressDiv">                                               
                           <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                            <Grid >
                                <label className="labelPrinterFields">Serial number:</label>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                            <Grid >
                                <input className="labelPrinterTextFields"type="text" id="serialno" value={this.state.currentAsset.serialNumber} />
                            </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                            <Grid >
                                <label className="labelPrinterFields">Part number:</label>
                            </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                            <Grid >
                                <input className="labelPrinterTextFields" type="text" id="partno" value={this.state.currentAsset.partNumber} />
                            </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >                                
                                <label className="labelPrinterFields">Description:</label>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                            <Grid>
                                <input className="labelPrinterTextFields"  type="text" id="description" value={this.state.currentAsset.itemDescription} />
                            </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >                        
                                <label className="labelPrinterFields">O-Ring type:</label>
                                </Grid>
                            </Grid>


                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                 <Grid>
                                <input className="labelPrinterTextFields" type="text" id="oringtype" value={this.state.currentAsset.typeCode} />                        
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                <label className="labelPrinterFields" >Job ID:</label>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid>
                                <input className="labelPrinterTextFields"  type="text" id="addressTextArea" value={this.state.currentAsset.jobID} />
                                 </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                <label className="labelPrinterFields"  >Open IR's:</label>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid>
                                <input className="labelPrinterTextFields"  type="text" id="openir" value={this.state.currentAsset.incidentReportNumber} />
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                               <Grid >
                                <label className="labelPrinterFields">Modifications:</label>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid>
                                <input className="labelPrinterTextFields"  type="text" id="modifications" value={this.state.currentAsset.modificationsCarriedOut} />
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                <label className="labelPrinterFields">Last Redress</label>
                                </Grid >
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid>
                                <input className="labelPrinterTextFields" type="text" id="redressby" value={this.state.currentAsset.concatenateRedress} />
                                </Grid>
                            </Grid >

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                 <label className="labelPrinterFields">Date:</label>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                    <input className="labelPrinterTextFields" type="text" id="checkoutdate" value={this.state.currentAsset.currentDate} />
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                <label className="labelPrinterFields">Checked by:</label>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                    <input className="labelPrinterTextFields" type="text" id="checkedby" value={this.state.currentAsset.checkedBy} />
                                </Grid>
                            </Grid>
                          
                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                <label className="labelPrinterFields">Comments:</label>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                            <Grid >
                                <input className="labelPrinterTextFields"  type="text" id="comments" value={this.state.currentAsset.comment} />
                            </Grid>
                            </Grid>                        

                            {/* <Grid container={true} justifyContent='left' alignItems='flex-end' spacing={1}>
                                <Grid >
                                    <button className="labelPrinterFields" id="updateLabel">Update Label</button>
                                </Grid>
                            </Grid>  */}
                        </div>
                    </div>

                    <div class="content">
                        <div id="labelImageDiv">
                            <Grid container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                <Grid >
                                    <h6 className="labelPrinterFields">Preview</h6><br />
                                </Grid>
                            </Grid>
                            <Grid container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                <Grid >
                                    <img id="labelImage" src="" />
                                </Grid>
                            </Grid>
                            <Grid container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                <Grid >
                                    <button className="labelPrinterFields" id="updatePreview">Load/Update Preview</button>
                                </Grid>
                            </Grid>
                        </div>
                        <div class="printControls">
                            <div id="printersDiv">
                                <Grid container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                    <Grid >
                                        <label className="labelPrinterFields" id="printerLabel" for="printersSelect">Printers</label>
                                    </Grid>
                                </Grid>
                                <Grid container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                    <Grid >
                                        <select className="labelPrinterTextFields" id="printersSelect"></select>
                                    </Grid>
                                </Grid>                             
                            </div>
                            <div id="printDiv">
                                <Grid container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                    <Grid >
                                        <button id="printButton">Print Label</button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Adding javascript file */}
                {AddLibrary(
                    'LabelPrinterJavaScript.js')}

            </div>
        );
    }
}



