import React, { Component } from "react";
import { QRScannerDialog } from './QRScannerDialog';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { getAsset, getHierarchy, AddRemoveParentSerialNoOfChildren, RemoveParentSerialNoOfGrandChildren, AddParentSerialNoOfGrandChildren, getSerialNoForAutocomplete } from '../services/LibraryService';
import './Hierarchy.css';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { ConfirmDialog } from './ConfirmDialog';
import { faPlus, faTrashAlt, faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Asset.css';
import { EngravedDialog } from './EngravedDialog';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

// Styling for autocomplete border 
const theme = createTheme({});

theme.overrides = {
    MuiOutlinedInput: {
        root: {
            "&$focused $notchedOutline": {
                borderColor: 'grey !important'
            }
        }
    },
};

export class Hierarchy extends Component {
    constructor(props) {
        super(props);
        this.searchSerienr = this.searchSerienr.bind(this);
        this.showGrandChildRow = this.showGrandChildRow.bind(this);

        this.state = {
            SerialNumberData: [], 
            SuperiorSerialNumberData: [],
            parentItemNo: "",
            ItemNoforParentBox: "",
            currentAsset: [{
                overordnetSerienr: "",
                serienr: "",
                itemNo: "",
                superiorSerialNo: "",
                grandChildren: [{
                    serienr: "",
                    itemNo: ""
                }],
            }],
            searchMode: false,
            addMode: false,
            noData: false,
            noChild: false,
            errors: { serienr: '' },
            errorData: null,
            isLoading: false,
            message: "",
            showModal: false,
            validSerialNo: true,
            validChildSerialNo: true,
            validGrandChildSerialNo: true,
            sameSNasParent: true,
            lenOfChildRes: null,
            grandChildCheckBoxStatusToShowArrow: false,
            newGrandChildRow: false,
            showGrandChildCheckBox: false,
            holdIndexOfDownArrow: null,
            prevParent: ""
        };

    }

    handleValidation = (parentSerialNo) => {
        let errors = { serienr: '', overordnetSerienr: '' };

        if (this.state.noData === false) {
            errors.serienr = "";
            this.setState({ noData: false })
        }

        if (this.state.noChild === false) {
            errors.serienr = "";
            this.setState({ noChild: false })
        }

        if (this.state.noData) {
            errors.serienr = 'This serial number has no hierarchy yet';
            this.setState({ noData: false })
        }

        if (this.state.noChild) {
            errors.serienr = "All children removed from the hierarchy '" + parentSerialNo + "'";
            this.setState({ noChild: false })
            this.setState({ searchMode: false });
            this.setState({ parentItemNo: "" });
        }
        this.setState({ errors });
    }

    // To handle update message Validation
    handleUpdateMsgValidation = () => {
        if (this.state.message) {
            this.setState({ message: "" })
        }

    }

    //  To check if entered no is parent or child 
    handleIfGrandChildOrChildEntered = () => {
        let errors = { checkEnteredSerialNo: '' };

        if (this.state.currentAsset[0].overordnetSerienr != this.state.currentAsset[0].superiorSerialNo) {
            errors.checkEnteredSerialNo = '* Enter parent serial number to edit hierarchy';
            this.setState({ showGrandChildCheckBox: false });
            this.setState({ newGrandChildRow: false })
        }

        this.setState({ errors });
    }

    // If grandchild already a part of another hierachy, show msg before adding to new hierarchy
    handleIfGrandChildIsAlreadyAPartOfAnotherHierarchy(prev) {
        let errors = { serienr: '' };
        if (prev !== "") {
            errors.serienr = 'Serial number you are trying to add belongs to hierarchy ' + this.state.prevParent;
        }
        this.setState({ errors });
    }

    handleInvalidSerialNo() {
        let errors = { serienr: '' };

        if (this.state.validSerialNo === false) {
            errors.serienr = 'Not a valid serial number';
            this.setState({ searchMode: false });
            this.setState({ parentItemNo: "" });   //check here
        }

        if (this.state.validChildSerialNo === false) {
            errors.serienr = 'Not a valid child serial number';
            this.setState({ sameSNasParent: false });
        }

        if (this.state.validGrandChildSerialNo === false) {
            errors.serienr = 'Not a valid grandchild serial number';
            this.setState({ sameSNasParent: false });
        }

        if (this.state.sameSNasParent === true) {
            errors.serienr = 'Serial number you are trying to add is already a part of this hierarchy';
            this.setState({ sameSNasParent: false })
        } 

        this.setState({ sameSNasParent: true })
        this.setState({ validSerialNo: true })
        this.setState({ validChildSerialNo: true })
        this.setState({ validGrandChildSerialNo: true })
        this.setState({ errors });
    }

    // for autocomplete onchange - to capture user typed input and load SuperiorSerialNumberData from API
    onChangeInputSuperiorSerialNo = value => {
        const caseInsensitiveValue = value.toUpperCase()
        getSerialNoForAutocomplete(caseInsensitiveValue).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                SuperiorSerialNumberData: responseData
                            })
                        });
                }
            })
            .catch((err) => {
                this.setState({ errorData: err.message });
            });
    }

    // for autocomplete onchange - to capture user typed input and load SerialNumberData from API
    onChangeInputSerialNo = value => {
        const caseInsensitiveValue = value.toUpperCase()
        getSerialNoForAutocomplete(caseInsensitiveValue).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                SerialNumberData: responseData

                            })
                        });
                }
            })
            .catch((err) => {
                this.setState({ errorData: err.message });
            });
    }

       //To capture selected item from autocomplete dropdown - And hide details on cancel ("x") - for entered serial no
    onChangeCaptureAutComSuperiorSerienr = (event, value) => {
        let currentAsset = [...this.state.currentAsset];
        currentAsset[0].overordnetSerienr = value;
        this.setState({ currentAsset });

        // If - to hide children on cancel, else -  searches for the selected dropdown value 
        if (this.state.currentAsset[0].overordnetSerienr == null) {
            this.setState({ searchMode: false });
            this.setState({ parentItemNo: "" });
            this.setState({ lenOfChildRes: null });
            // For hiding grandchild button and granchild row
            this.setState({ showGrandChildCheckBox: false });
            this.setState({ addGrandnewGrandChildRowChild: false })
            this.handleValidation();
        }
        else {
            this.searchSerienr()
        }

    }

    //To capture selected item from autocomplete dropdown -  for child
    onChangeCaptureAutComSerienr = i => (event, value) => {

        let currentAsset = [...this.state.currentAsset];
        if (value === null) {
            this.state.currentAsset[i].serienr = [];
        }
        else {
            const caseInsensitiveValue = value.toUpperCase()
            currentAsset[i].serienr = caseInsensitiveValue;
        }
        currentAsset[i].overordnetSerienr = this.state.currentAsset[0].overordnetSerienr;
        this.setState({ currentAsset });

        // to remove invalid child serial number message
        this.setState({ validChildSerialNo: true });
        this.handleValidation();

        //to get previous parent serial no of child for validation
        getAsset(this.state.currentAsset[i].serienr).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                prevParent: responseData['overordnetSerienr']
                            })
                            let prevParentOfChild = this.state.prevParent;
                            this.handleIfGrandChildIsAlreadyAPartOfAnotherHierarchy(prevParentOfChild);
                        });
                }
            })
    }


   //To capture selected item from autocomplete dropdown - for grandchild
    onChangeCaptureAutComSerienrGC = (i, j) => (event, value) => {

        let currentAsset = [...this.state.currentAsset];
        if (value === null) {
            this.state.currentAsset[i].grandChildren[j].serienr = [];
        }
        else {        
            const caseInsensitiveValue = value.toUpperCase()
            this.state.currentAsset[i].grandChildren[j].serienr = caseInsensitiveValue;         
        }
        currentAsset[i].overordnetSerienr = this.state.currentAsset[0].overordnetSerienr;
        this.setState({ currentAsset });     
        // to remove invalid grandchild serial number messgae
        this.setState({ validGrandChildSerialNo: true });
        this.handleValidation();

        //to get previous parent serial no of grand child for validation
        getAsset(this.state.currentAsset[i].grandChildren[j].serienr).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                prevParent: responseData['overordnetSerienr']
                            })
                            let prevParentOfGrandChild = this.state.prevParent;
                            this.handleIfGrandChildIsAlreadyAPartOfAnotherHierarchy(prevParentOfGrandChild);
                        });
                }
            })    
    }

    handleSuperiorSerialNoFromScanner = i => (data) => {
        // To list and select the scanned value from autocomplete dropdown
        this.onChangeInputSuperiorSerialNo(data)
       let currentAsset = [...this.state.currentAsset];
        currentAsset[0].overordnetSerienr = data;
        this.setState({ currentAsset });

        // below lines to change focus to search autocomplete box
        const parentScannedNo = document.querySelector("input");
        parentScannedNo.focus();
        parentScannedNo.select();

        // to search on receiving from scanner without manual enter
        this.searchSerienr()
    }


    handleSerialNoFromScanner = i => (data) => {
        // To list and select the scanned value from autocomplete dropdown
        this.onChangeInputSerialNo(data)
        //this.onChangeCaptureAutComSerienr()

        let currentAsset = [...this.state.currentAsset];
        if (data === '' || data === null) {
            currentAsset[i].serienr = [];
        }
        else {
            currentAsset[i].serienr = data;
        }
        currentAsset[i].overordnetSerienr = this.state.currentAsset[0].overordnetSerienr; // for same parent serial no for child
        this.setState({ currentAsset });

        // below line to not focus the parent but the child autocomplete box, 
        const childScannedNo = document.querySelector("input1");

    }


    //Returns children of superior serial number
    searchSerienr() {

        //to get itemno for entered serial no
        getAsset(this.state.currentAsset[0].overordnetSerienr).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                parentItemNo: responseData['varenummer']
                            })
                        });
                }

            })

        this.handleValidation();
        let parent = this.state.currentAsset[0].overordnetSerienr
        getHierarchy(this.state.currentAsset[0].overordnetSerienr).then(
            (response) => {
                if (response.ok) {
                    this.setState({ isLoading: true })
                    response.json()
                        .then((responseData) => {

                            //returns the number of children returned
                            let lengthOfResponse = Object.keys(responseData).length
                            // If - to check if children exist 
                            if (lengthOfResponse > 0) {
                                this.removeAllFields(0); // // deletes all rows except first if 1 instead of 0
                                for (var i = 0; i < lengthOfResponse; i++) {
                                    this.addFormFields();
                                    let currentAsset = [...this.state.currentAsset];
                                    //currentAsset[i].overordnetSerienr = parent;
                                    currentAsset[i].serienr = responseData[i]['serialNo'];
                                    currentAsset[i].itemNo = responseData[i]['itemNo'];
                                    currentAsset[i].superiorSerialNo = responseData[i]['superiorSerialNo'];
                                    //let getChildOrGCSerialNoForHighlighting = this.state.currentAsset[i].serienr

                                    //returns the number of grandchildren 
                                    let temp = responseData[i]['grandChildSerialNos']
                                    // Added "+1" to add a new granchild row, after listing all grandchildren
                                    let lengthOfGrandChildren = Object.keys(temp).length + 1                                
                                    for (var j = 0; j < lengthOfGrandChildren; j++) {
                                        let currentAsset = [...this.state.currentAsset];                                    
                                        currentAsset[i].grandChildren[j] = {};
                                        if (j != lengthOfGrandChildren - 1) {
                                            currentAsset[i].grandChildren[j].serienr = responseData[i]['grandChildSerialNos'][j]['serialNo'];
                                            currentAsset[i].grandChildren[j].itemNo = responseData[i]['grandChildSerialNos'][j]['itemNo'];
                                        }
                                        else {
                                            currentAsset[i].grandChildren[j].serienr = "";
                                            currentAsset[i].grandChildren[j].itemNo = "";
                                        }                                                                            
                                    }                                  
                                    this.setState({ currentAsset });
                                    this.setState({ searchMode: true });
                                    this.setState({ showGrandChildCheckBox: true });
                                    this.setState({ newGrandChildRow: false });
                                    this.setState({ grandChildCheckBoxStatusToShowArrow: false });
                                    if (this.state.currentAsset[i].serienr == this.state.currentAsset[i].overordnetSerienr) {
                                        this.removeLastRow(i); // deletes that particular row    
                                    }
                                    this.setState({ currentAsset });
                                    this.setState({ searchMode: true });
                                }                             
                                if (parent !== '') {
                                    this.addFormFields(); // adds new field
                                }
                            }
                            /* to make entered serial no stick to search box */
                            let currentAsset = [...this.state.currentAsset];
                            currentAsset[0].overordnetSerienr = parent                          
                            this.setState({ currentAsset });

                            this.setState({ isLoading: false })
                            this.setState({ lenOfChildRes: lengthOfResponse })
                            this.handleIfGrandChildOrChildEntered();

                        })
                }

                // 406 - SerialNo not available on DB
                else if (response.status === 406) {
                    this.setState({ validSerialNo: false })
                    this.setState({ sameSNasParent: false })
                    this.setState({ searchMode: false }); // false to hide rows below
                    this.handleInvalidSerialNo();
                }

                // If serialNo has no children
                else {
                    this.setState({ lenOfChildRes: 0 })
                    this.removeAllFields(1);
                    let currentAsset = [...this.state.currentAsset];
                    currentAsset[0].serienr = ""
                    currentAsset[0].overordnetSerienr = parent;
                    currentAsset[0].grandChildren = [];
                    currentAsset[0].superiorSerialNo = this.state.currentAsset[0].overordnetSerienr; // to enable 'add child' while creating hierarchy
                    this.setState({ currentAsset });
                    this.setState({ noData: true })
                    this.setState({ searchMode: true }); // false to hide rows below
                    this.setState({ showGrandChildCheckBox: false });
                    this.handleValidation();
                }

            })
            .catch((err) => {
                this.setState({ isLoading: false, errorData: err.message });
            });

        //to get itemno for parent serial no
        getAsset(this.state.currentAsset[0].superiorSerialNo).then(


            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                ItemNoforParentBox: responseData['varenummer']
                            })
                        });
                }

            })
    }

    //Update - add parentSerialNo of Child 
    async updateChild(i) {

        if (this.state.errors.serienr) {
            this.state.errors.serienr = "";
        }

        // below 4 lines to get all the child serilanos of the parent
        // lenOfChildRes is number of children returned by a parent from responseData
        var hold = []

        if (this.state.currentAsset[0].serienr !== "") {
            for (var j = 0; j < this.state.lenOfChildRes; j++) {
                hold = hold.concat(this.state.currentAsset[j].serienr)
            }
        }

        // below 'if' to check if the entered serialnumber is not same as parent or child(that already exist).
        if ((this.state.currentAsset[i].serienr !== this.state.currentAsset[i].overordnetSerienr) && !hold.includes(this.state.currentAsset[i].serienr)) {
            await AddRemoveParentSerialNoOfChildren(this.state.currentAsset[i].serienr,
                this.state.currentAsset[i]).then(
                    (response) => {
                        if (response.ok) {
                            response.json()
                                .then((responseData) => {
                                    this.setState({
                                        currentAsset: [...this.state.currentAsset, { overordnetSerienr: "", serienr: [], grandChildren: [] }]
                                    })
                                });
                            // To refresh and add Children to the currrent page, if added serialNo has children
                            this.searchSerienr()

                        }
                        else {
                            this.setState({ validChildSerialNo: false })
                            this.handleInvalidSerialNo();
                        }
                    })
        }
        // display error message if same as parent or child
        else {
            this.setState({ sameSNasParent: true })
            this.handleInvalidSerialNo();
        }
    }

    //Update - add parentSerialNo of GrandChild 
    async updateGrandChild(element, element1, i, j) {     

        // below 4 lines to get all the grand child serilanos of a child
        // j is the grandchildren length below
        var hold = []     
        if (this.state.currentAsset[i].grandChildren[j].serienr !== "") {
            for (var k = 0; k < j; k++) {
                hold = hold.concat(this.state.currentAsset[i].grandChildren[k].serienr)            
            }
            }

        // below 'if' to check if the entered serialnumber is not same as parent or grandchild(that already exist).
        if ((this.state.currentAsset[i].grandChildren[j].serienr !== this.state.currentAsset[i].overordnetSerienr) && !hold.includes(this.state.currentAsset[i].grandChildren[j].serienr)) {
            await AddParentSerialNoOfGrandChildren(this.state.currentAsset[i].grandChildren[j].serienr, this.state.currentAsset[i]).then(
                (response) => {
                    if (response.ok) {
                        response.json()
                            .then((responseData) => {                             
                            });
                        // To refresh and add Children to the currrent page, if added serialNo has children
                        this.searchSerienr()
                    }
                    else {
                        this.setState({ validGrandChildSerialNo: false })
                        this.handleInvalidSerialNo();
                    }
                })
        }
        // display error message if same as parent or grandchild
        else {
            this.setState({ sameSNasParent: true })
            this.handleInvalidSerialNo();
        }
    }

    // remove parentSerialNo of Child  
    async deleteChild(i) {
        let currentAsset = [...this.state.currentAsset];
        let parentSerialNo = this.state.currentAsset[i].overordnetSerienr
        currentAsset[i].overordnetSerienr = "";
        await AddRemoveParentSerialNoOfChildren(this.state.currentAsset[i].serienr,
            this.state.currentAsset[i]).then(
                (response) => {
                    if (response.ok) {
                        response.json()
                            .then((responseData) => {
                                this.removeLastRow(i);
                                // lenOfChildRes for update validation - decrement for removal of row
                                this.setState({ lenOfChildRes: this.state.lenOfChildRes - 1 })
                                // If deleting all children from hierarchy, display message
                                if (i < 1 && this.state.currentAsset.length == 1) {
                                    this.setState({ noChild: true })
                                    this.handleValidation(parentSerialNo);
                                    this.setState({ showGrandChildCheckBox: false });
                                }
                                // below three lines to make parentserialno stick to search box
                                let currentAsset = [...this.state.currentAsset];
                                currentAsset[i].overordnetSerienr = parentSerialNo;
                                this.setState({ currentAsset });

                            });
                    }
                })
    }

    // remove parentSerialNo of grandChild 
    async deleteGrandChild(i, j) {
        let currentAsset = [...this.state.currentAsset];
        let parentSerialNo = this.state.currentAsset[i].overordnetSerienr
        //    currentAsset[i].overordnetSerienr = "";
        await RemoveParentSerialNoOfGrandChildren(this.state.currentAsset[i].grandChildren[j].serienr,
            this.state.currentAsset[i]).then(
                (response) => {
                    if (response.ok) {
                        response.json()
                            .then((responseData) => {
                                this.removeLastRowGC(i, j);
                                // If deleting all children from hierarchy, display message
                                if (i < 1 && this.state.currentAsset.length == 1) {
                                    this.setState({ noChild: true })
                                    this.handleValidation(parentSerialNo);
                                }
                            });
                    }

                })
    }

    addFormFields() {
        this.setState(({
            currentAsset: [...this.state.currentAsset, { overordnetSerienr: "", serienr: [], grandChildren: [] }]
        }))
    }
    // For children
    removeLastRow(i) {
        let currentAsset = this.state.currentAsset;
        currentAsset.splice(i, 1);
        this.setState({ currentAsset });
    }

    // For grandchildren
    removeLastRowGC(i, j) {
        let currentAsset = this.state.currentAsset;
        let grandChildren = this.state.currentAsset[i].grandChildren;
        grandChildren.splice(j, 1);
        this.setState({ currentAsset });
    }

    removeAllFields(i) {
        let currentAsset = [...this.state.currentAsset];
        currentAsset[0].overordnetSerienr = this.state.currentAsset[0].overordnetSerienr;
        currentAsset.splice(i);
        this.setState({ currentAsset });
    }


    /* sets grandchild check box status to show down arrow (on click of checkbox) */
    setDownArrowforAddingGrandChild() {
       // e.preventDefault();
        this.setState(prevState => ({
            grandChildCheckBoxStatusToShowArrow: !prevState.grandChildCheckBoxStatusToShowArrow,
        }));
        this.setState({ newGrandChildRow: false })

    }

    /* sets newGrandhildRow to show new grandchild row (on click of down arrow) */
    showGrandChildRow(e, i) {
        let value = i
        this.state.holdIndexOfDownArrow = value;
        e.preventDefault();

        //below two lines to remove unsubmitted grandchild value in add grandchild box
        const len = this.state.currentAsset[i].grandChildren.length
        this.state.currentAsset[i].grandChildren[len-1].serienr = "";

        this.setState(prevState => ({
            newGrandChildRow: !prevState.newGrandChildRow,
        }));    
    }

    //clear "refused" serialno when no was pressed
    dontupdateGrandChild(i) {
        //below lines to remove unsubmitted grandchild value in add grandchild box
        const len = this.state.currentAsset[i].grandChildren.length
        let currentAsset = [...this.state.currentAsset];
        currentAsset[i].grandChildren[len - 1].serienr = "";
        this.setState({ currentAsset });

        //clears error message
        let errors = { serienr: '' };   
            errors.serienr = "";        
        this.setState({ errors });
    }

    dontupdateChild(i) {
        //below lines to remove unsubmitted grandchild value in add grandchild box
        const len = this.state.currentAsset.length
        let currentAsset = [...this.state.currentAsset];
        this.state.currentAsset[len-1].serienr = [];
        this.setState({ currentAsset });
    } 

    render() {
        let { errorData, isLoading } = this.state;
        if (errorData) {
            return (
                <div className="errorData"> {errorData} </div>
            )
        }
        if (isLoading) {
            return (
                <div className="errorData"> Loading... </div>
            )
        }
        const { errors } = this.state;
        const parentItemNo = this.state.parentItemNo;
        const ItemNoforParentBox = this.state.ItemNoforParentBox;
        const currentAsset = Object.values(this.state.currentAsset);
        const CAToRender = this.state.currentAsset.filter(ca => ca.serienr)
        const numRows = CAToRender.length
        let childScannedNo;
        let parentScannedNo;

        // user role is "undefined" if they dont have update rights.
        let userRole;
        let roles = this.props.user.account.idTokenClaims?.roles;
        if (roles && roles.length !== 0) {
            userRole = roles.find(item => item === 'AssetApp.Update') || 'Read'
        }
        return (
            <div>
                <Grid container={true} justifyContent='center' alignItems='center'>
                    <Grid item>
                        <p className="header">Create and Update Hierarchy</p>
                    </Grid><br />
                </Grid>

                <Grid container={true} justifyContent='center' alignItems='center'>
                    {/* To display search box for entereing serial number  */}
                    <Grid item>
                        <Box mt={2}>
                            <MuiThemeProvider theme={theme}>
                                <Autocomplete
                                    id="overordnetSerienr"
                                    noOptionsText="No options found"
                                    value={this.state.currentAsset[0].overordnetSerienr}
                                    options={this.state.SuperiorSerialNumberData}
                                    renderOption={(option) => (
                                        <Typography class="typography">{option}</Typography>
                                    )}
                                    getOptionLabel={option => option}
                                    onChange={this.onChangeCaptureAutComSuperiorSerienr}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            this.searchSerienr();
                                        }
                                    }}
                                    autoHighlight={true}
                                    className="hierarchySearchAutoCompleteWidth"
                                    renderInput={params => (
                                        <TextField
                                            parentScannedNo={input => { parentScannedNo = input; }}
                                            {...params} onChange={e => this.onChangeInputSuperiorSerialNo(e.target.value)} label={parentItemNo == "" ? "Serial Number" : parentItemNo} variant={"outlined"}
                                            size="small" InputLabelProps={{ className: "inputLabel", shrink: true }} fullWidth />
                                    )}
                                />
                            </MuiThemeProvider>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box mt={2}>
                            <EngravedDialog handlerFromParant={this.handleSuperiorSerialNoFromScanner(0)} />
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box mt={2}>
                            <QRScannerDialog handlerFromParant={this.handleSuperiorSerialNoFromScanner(0)} />
                        </Box>
                    </Grid>

                </Grid>

                {/*checkbox to display down arrow(for adding Grandchild row) on select(tickMark) */}
                <Grid item>
                    {this.state.showGrandChildCheckBox === true ? (
                        <Grid container={true} justifyContent='center' alignItems='left'>
                            <Grid item>                              
                                <input onClick={(e) => { this.setDownArrowforAddingGrandChild(e); }} type="checkbox" />
                                <label style={{ marginLeft: "15px" }}>
                                    Add new grand child 
                                </label>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
               
                <Grid container={true} justifyContent='center' alignItems='flex-end'>
                    {errors.serienr != '' && <span className="error">{this.state.errors.serienr}</span>}
                    {errors.checkEnteredSerialNo != '' && <span className="errorCheckingEnteredSerialNo">{this.state.errors.checkEnteredSerialNo}</span>}
                </Grid>

                <form>

                    {/* Display parent serial no */}
                    <Grid container={true} justifyContent='center' alignItems='center'>
                        {this.state.searchMode === true ? (
                            <Grid item>
                                {numRows > 1 ? (
                                    <Box mt={2}>
                                        <TextField
                                            className="parentWidthInHier"
                                            type="text"
                                            disabled={true}
                                            label={this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo ? "Parent - " + parentItemNo : "Parent - " + ItemNoforParentBox}
                                            variant="outlined"
                                            size="small"
                                            value={currentAsset[0].superiorSerialNo}
                                            InputLabelProps={{ className: "inputLabel", shrink: true }}
                                        />
                                    </Box>
                                ) : null}
                            </Grid>
                        ) : false}
                    </Grid>

                    {/* To display children autocomplete box */}
                    {currentAsset.map((element, index) => {
                        const grandChild = Object.values(element.grandChildren);
                        return ( // added return to add previous statement

                            <div className="spaceBtwChild" key={index + element.serienr}>

                                <Grid container={true} justifyContent='center' alignItems='center'>
                                    <Grid Item>
                                    </Grid>

                                    {/* shows down arrow button */}
                                      <Grid item>
                                        {this.state.grandChildCheckBoxStatusToShowArrow === true && this.state.showGrandChildCheckBox === true && index + 2 <= numRows ? (
                                            <Grid container={true} justifyContent='center' alignItems='left'>
                                                <Grid item>
                                                  <Tooltip title={<span className='toolTip'>Expand </span>} placement="top" arrow='true'>
                                                    <button className="addGrandChildRowWdth" 
                                                     style={{
                                                        width: '20px',
                                                        fontSize: 30,
                                                        height: 35,
                                                         color: '#79797F',
                                                        fontFamily: "Arial",                                               
                                                        background: 'none',
                                                        border: 'none',
                                                        margin: "0px",
                                                        padding: "0px",
                                                        cursor: 'pointer'                                            
                                                        }} onClick={(e) => { this.showGrandChildRow(e, index); }}>
                                                        <FontAwesomeIcon style={{ fontSize: 15 }}                                                          
                                                                icon={faAngleDown}
                                                        />
                                                     </button>
                                                   </Tooltip>
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                    </Grid>                               

                                    {this.state.searchMode === true ? (
                                        <Grid item>
                                            {/* numRows is physical rows on screen, index is data from backend, starts from 0 */}
                                            {/* 1 = 1 below means no data from backend, jus return addNewChild */}
                                            {index + 1 >= numRows ? (
                                                <MuiThemeProvider theme={theme}>
                                                    <Autocomplete
                                                        id="serienrC"
                                                        // disabled becomes false (field become editable), if both conditions are true
                                                        disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true}
                                                        noOptionsText="No options found"
                                                        value={element.serienr}
                                                        options={this.state.SerialNumberData}
                                                        renderOption={(option) => (
                                                            <Typography class="typography">{option}</Typography>
                                                        )}
                                                        getOptionLabel={option => option}
                                                        onChange={this.onChangeCaptureAutComSerienr(index)}
                                                        className={(this.state.grandChildCheckBoxStatusToShowArrow === true) ? "hierarchyAutoCompleteAddWidthWithGCButton" : "hierarchyAutoCompleteAddWidth"}
                                                        autoHighlight={true}
                                                        renderInput={params => (
                                                            <TextField
                                                                childScannedNo={input => { childScannedNo = input; }}
                                                                {...params} onChange={e => this.onChangeInputSerialNo(e.target.value)} label="Add new child" variant={"outlined"}
                                                                size="small" InputLabelProps={{ style: { fontSize: 13, color: '#33333F', fontFamily: "Arial" }, }} fullWidth />
                                                        )}
                                                    />
                                                </MuiThemeProvider>
                                            ) : (
                                                <MuiThemeProvider theme={theme}>
                                                    <Autocomplete
                                                        disabled={true}
                                                        // id becomes serienrHighlight, if condition is true
                                                        id={(element.serienr == this.state.currentAsset[0].overordnetSerienr) ? "serienrCHighlight" : "serienrC"}
                                                        value={element.serienr}
                                                        options={this.state.SerialNumberData}
                                                        renderOption={(option) => (
                                                            <Typography class="typography">{option}</Typography>
                                                        )}
                                                        getOptionLabel={option => option}
                                                            onChange={this.onChangeCaptureAutComSerienr(index)}
                                                            className={(this.state.grandChildCheckBoxStatusToShowArrow === true ) ? "hierarchyAutoCompleteRemoveWidthWithGCButton" : "hierarchyAutoCompleteRemoveWidth"}
                                                        renderInput={params => (
                                                            <TextField {...params} onChange={e => this.onChangeInputSerialNo(e.target.value)} label={element.itemNo} variant={"outlined"}
                                                                size="small" InputProps={{ style: { fontSize: 26, fontFamily: "Arial" } }} InputLabelProps={{ className: "inputLabel", shrink: true }} fullWidth />
                                                        )}
                                                        />                                                     
                                                    <Grid item>
                                                        {/* 2 > 1 below means data available from backend, add scanner to rows*/}
                                                        {index + 2 > numRows ? (
                                                            <EngravedDialog handlerFromParant={this.handleSerialNoFromScanner(index)} />

                                                        ) : null}
                                                    </Grid>
                                                    <Grid item>
                                                        {index + 2 > numRows ? (
                                                            <QRScannerDialog handlerFromParant={this.handleSerialNoFromScanner(index)} />

                                                        ) : null}
                                                    </Grid>
                                                </MuiThemeProvider>
                                            )}
                                        </Grid>
                                    ) : false}

                                    {/* Add child scanner and add remove buttons */}
                                    {this.state.searchMode === true ? (
                                        <Grid item>
                                            {index + 2 > numRows ? (
                                                <EngravedDialog disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true} handlerFromParant={this.handleSerialNoFromScanner(index)} />

                                            ) : null}
                                        </Grid>
                                    ) : false}

                                    {this.state.searchMode === true ? (
                                        <Grid item>
                                            {index + 2 > numRows ? (
                                               <QRScannerDialog disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true} handlerFromParant={this.handleSerialNoFromScanner(index)} />
                                            ) : null}
                                        </Grid>
                                    ) : false}

                                    {this.state.searchMode === true ? (
                                        <Grid item>
                                            {index + 2 > numRows ? (
                                                <ConfirmDialog icon={faPlus} desc="Are you sure you want to add this Asset?" no={() => this.dontupdateChild(index)} confirm={() => this.updateChild(index)} validationMsg={this.handleUpdateMsgValidation} disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true} />

                                            ) : (

                                                    <ConfirmDialog icon={faTrashAlt} desc="Are you sure you want to remove this Asset?" no={() => this.dontupdateChild(index)} confirm={() => this.deleteChild(index)} validationMsg={this.handleUpdateMsgValidation} disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true} />
                                            )}
                                        </Grid>
                                    ) : false}                                                                                               
                                </Grid>                                                         

                                {/* To display grandchildren autocomplete box and add remove button 
                                  * to display diff color for button when disabled - bcolor={(userRole == "AssetApp.Update") ? "black" : "grey" }  */}
                                {grandChild.map((element1, index1) => {
                                    return ( // added return to add previous statement
                                        <div className="spaceBtwChildandGrandChild" key={index1 + element1.serienr}>
                                            {
                                                this.state.searchMode === true ? (
                                                    <Grid container={true} justifyContent='center' alignItems='left'>                                                   
                                                            <Grid item>                                                          
                                                            {/* (grandChild.length - 1) is to reduce the extra new GC row that gets added by default. If no data from backend, return add new grandChild */}
                                                            {index1 >= grandChild.length - 1 ? (
                                                                    <Grid item>
                                                                    {/* Show below autocomplete, only when 'Add Grand child Row' button is clicked*/}
                                                                    {this.state.newGrandChildRow == true && this.state.holdIndexOfDownArrow == index? (
                                                                    <MuiThemeProvider theme={theme}>
                                                                        <Autocomplete
                                                                            id="serienrGC"
                                                                            // disabled becomes false (field become editable), if both conditions are true
                                                                            disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true}
                                                                            noOptionsText="No options found"
                                                                            value={element1.serienr}
                                                                            options={this.state.SerialNumberData}
                                                                            renderOption={(option) => (
                                                                                <Typography class="typography">{option}</Typography>
                                                                            )}
                                                                            getOptionLabel={option => option}
                                                                                onChange={this.onChangeCaptureAutComSerienrGC(index, index1)}
                                                                                className="hierarchyAddWidthGrandChildren"

                                                                            autoHighlight={true}
                                                                            renderInput={params => (
                                                                                <TextField
                                                                                    childScannedNo={input => { childScannedNo = input; }}
                                                                                    {...params} onChange={e => this.onChangeInputSerialNo(e.target.value)} label="Add grand child" variant={"outlined"}
                                                                                    size="small" InputLabelProps={{ style: { fontSize: 13, color: '#33333F', fontFamily: "Arial" }, }} fullWidth />
                                                                            )}
                                                                            />                                                                          
                                                                  </MuiThemeProvider>
                                                                  ) : null}
                                                                  </Grid>
                                                               ) : (                                                                                                                                                                         
                                                                  <MuiThemeProvider theme={theme}>
                                                                       <Autocomplete                                                          
                                                                        id={(element1.serienr == this.state.currentAsset[0].overordnetSerienr) ? "serienrGCHighlight" : "serienrGC"}
                                                                        disabled={true}
                                                                        value={element1.serienr}
                                                                        options={this.state.SerialNumberData}
                                                                        renderOption={(option) => (
                                                                            <Typography class="typography">{option}</Typography>
                                                                        )}
                                                                        getOptionLabel={option => option}
                                                                        onChange={this.onChangeCaptureAutComSerienrGC(index, index1)}
                                                                        className="hierarchyRemoveWidthGrandChildren"
                                                                        renderInput={params => (
                                                                            <TextField {...params} onChange={e => this.onChangeInputSerialNo(e.target.value)} label={element1.itemNo} variant={"outlined"}
                                                                                size="small" InputProps={{ style: { fontSize: 26, fontFamily: "Arial" } }} InputLabelProps={{ className: "inputLabel", shrink: true }} fullWidth />
                                                                        )}
                                                                        />
                                                                    <Grid item>
                                                                        {/* 2 > 1 below means data available from backend, add scanner to rows*/}
                                                                            {index1 >= grandChild.length - 1 && this.state.holdIndexOfDownArrow == index ? (
                                                                            <EngravedDialog handlerFromParant={this.handleSerialNoFromScanner(index)} />

                                                                        ) : null}
                                                                    </Grid>
                                                                    <Grid item>
                                                                            {index1 >= grandChild.length - 1 && this.state.holdIndexOfDownArrow == index ? (
                                                                            <QRScannerDialog handlerFromParant={this.handleSerialNoFromScanner(index)} />
                                                                        ) : null}
                                                                    </Grid>
                                                               </MuiThemeProvider>
                                                          )}
                                                          </Grid>
                                 
                                                        {/* EngravedDialog and ScannerDialog for add grand child row */}
                                                        <Grid item>
                                                            {/* 2 > 1 below means data available from backend, add scanner to rows*/}
                                                            {index1 >= grandChild.length - 1 && this.state.newGrandChildRow == true && this.state.holdIndexOfDownArrow == index ? (
                                                                <EngravedDialog disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true} handlerFromParant={this.handleSerialNoFromScanner(index)} />

                                                            ) : null}
                                                        </Grid>
                                                        <Grid item>
                                                            {index1 >= grandChild.length - 1 && this.state.newGrandChildRow == true && this.state.holdIndexOfDownArrow == index ? (
                                                                <QRScannerDialog disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true} handlerFromParant={this.handleSerialNoFromScanner(index)} />

                                                            ) : null}
                                                        </Grid>

                                                        {/* Add or remove button for grand child row */}
                                                        {this.state.searchMode === true ? (
                                                            <Grid item>
                                                                {index1 >= grandChild.length - 1 ? (
                                                                     <Grid item>
                                                                        {this.state.newGrandChildRow == true && this.state.holdIndexOfDownArrow == index ? (

                                                                            <ConfirmDialog icon={faPlus} desc="Are you sure you want to add this Asset?" no={() => this.dontupdateGrandChild(index)} confirm={() => this.updateGrandChild(element.serienr, element1.serienr, index, index1)} validationMsg={this.handleUpdateMsgValidation} disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true} />
                                                                        ) : null}
                                                                    </Grid>
                                                                ) : (
                                                                        <ConfirmDialog style={{ marginTop: "8px" }} icon={faTrashAlt} desc="Are you sure you want to remove this Asset?" no={() => this.dontupdateGrandChild(index)} confirm={() => this.deleteGrandChild(index, index1)} validationMsg={this.handleUpdateMsgValidation} disabled={(userRole == "AssetApp.Update" && (this.state.currentAsset[0].overordnetSerienr == this.state.currentAsset[0].superiorSerialNo)) ? false : true} />
                                                                )}
                                                            </Grid>
                                                       ) : false}
                                                     </Grid>
                                                ) : false}                                         
                                        </div>
                                    )
                                })}                                                                                                                                       
                            </div>
                        )
                    })}

                    <br/>
                  
                </form>               
                <br />
            </div>
        );
    }
}






