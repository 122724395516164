//Add 'api/asset/home' at end of test and production URL to check if API is up and running

module.exports = {
    apiBaseUrl: 'https://x3tewnoh4d9t2whlzvcvxunhamifdq6el-welltecglobal.msappproxy.net/app' // production
    //apiBaseUrl: 'https://iyithxaz241nwow9tocha0371mmk6pmh5yv60uglo-welltecglobal.msappproxy.net/app' //development
    //apiBaseUrl: 'https://localhost:44347'  
}



