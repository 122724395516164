import React, { Component } from "react";
import { getAsset, updateAsset, getLokationsKode, getVarenummer, getPlaceCode, getSerialNoForAutocomplete, placeCodes } from '../services/LibraryService';
import { Button } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import './Asset.css';
import { QRScannerDialog } from './QRScannerDialog';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from "@material-ui/core/styles";
import { ConfirmDialog } from './ConfirmDialog';
import { EngravedDialog } from './EngravedDialog';
import Tooltip from '@material-ui/core/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faPrint } from '@fortawesome/free-solid-svg-icons';

{/* import logo from '../images/Engraving.webp';
<img src={logo} alt="logo" className="photo" />
*/}

// Styling for autocomplete box color
const theme = createTheme({});
theme.overrides = {

    MuiOutlinedInput: {
        root: {
            "&$focused $notchedOutline": {
                borderColor: '#79797F !important'
            }
        }
    }

};

// For changing text field font color to darker, while disabled
const DarkerDisabledTextField = withStyles({
    root: {

        "& .MuiInputBase-root.Mui-disabled": {
            color: "#33333F"
        }
    }
})(TextField);

export class Asset extends Component {
    constructor(props) {
        super(props);

        this.onChangeLokationsKode = this.onChangeLokationsKode.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.searchSerienr = this.searchSerienr.bind(this);
        this.onChangeBeskrivelse = this.onChangeBeskrivelse.bind(this);
        this.onChangeCommentsToSerialNo = this.onChangeCommentsToSerialNo.bind(this);
        this.updateAsset = this.updateAsset.bind(this);
        this.onChangeModificationStatus = this.onChangeModificationStatus.bind(this);
        this.handleData = this.handleData.bind(this);
        this.labelPrint = this.labelPrint.bind(this);

        this.state = {
            SerialNumberData: [],
            LokationsKodeData: [],
            VarenummerData: [],
            PlaceCodeData: [],

            currentAsset: {
                serienr: null,
                varenummer: "",
                status: "",
                modificationStatus: "",
                beskrivelse: "",
                lokationsKode: "",
                lokationsBeskrivelse: "",
                placed: "",
                commentsToSerialNo: "",
                sidsteLevel1Point: null,
                sidsteLevel3Point: null,
                sidsteLevel1Dato: "",
                sidsteLevel3Dato: "",
                overordnetSerienr: "",
            },

            status: [
                { id: 0, key: "Green" },
                { id: 1, key: "Yellow" },
                { id: 2, key: "Red" },
            ],

            //Not enough data to find key for other ids
            modificationStatus: [
                { id: 0, key: "Inspection_High_Risk" },  
                { id: 2, key: "Possible_Misrun_High_Risk" },
                { id: 3, key: "Possible_Misrun_Low_Risk" },
                { id: 5, key: "Inspection_Low_Risk" },
                { id: 7, key: "Upgrade_Available" },
            ],

            errorData: null, // For displaying server down and network errors
            errors: { serienr: '', commentstoSerialNo: '', emptyValues: '', validSerialNo: '' }, // For displaying validation errors
            noData: false, // When no data returned from API
            isLoading: true,
            message: "",
            conflict: false,
            statusToolTip: '',
            modificationStatusToolTip: ''
        };

    }

    //send serial number to label printer page  
    async labelPrint() {
        var serialNo = this.state.currentAsset.serienr
        window.location.href = '/labelPrinter?serialNo=' + serialNo;
    }

    static renderUserProfile(props) {
        return (
            <div>
                <h6><Button class="button button-grey" onClick={props.authButtonMethod}>Sign out</Button></h6>
            </div>
        );
    }

    // Set the child(scanner dialog.js) scanned data(serial number) to parent(asset.js)
    handleData(data) {
        // To select the scanned value from autocomplete dropdown
        this.onChangeInputSerialNo(data)
        this.onChangeCaptureAutComSerienr(data)
        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                serienr: data
            }


        }));
        // below lines to change focus to search autocomplete box
        const scannedNo = document.querySelector("input");
        scannedNo.focus();
        scannedNo.select();
        // to search on receiving from scanner without manual enter
        // this.searchSerienr()
    }

    // For autocomplete fields
    async componentDidMount() {
        this.setState({
            isLoading: false
        })
    }

    onChangeStatus(e) {

        this.state.statusToolTip = this.state.status[e.target.value]['key']

        const Status = parseInt(e.target.value);
        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                status: Status
            }
        }));

    }

    onChangeModificationStatus(e) {

        this.state.modificationStatusToolTip = this.state.modificationStatus[e.target.value]['key']

        const ModificationStatus = parseInt(e.target.value);
        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                modificationStatus: ModificationStatus
            }
        }));
    }


    onChangeBeskrivelse(e) {
        const Beskrivelse = e.target.value;
        this.setState(function (prevState) {
            return {
                currentAsset: {
                    ...prevState.currentAsset,
                    beskrivelse: Beskrivelse
                }
            };
        });
    }


    // for autocomplete onchange - below three methods to load Data from API
    onChangeLoadVarenummer = value => {
        getVarenummer().then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                isLoading: false,
                                VarenummerData: responseData
                            })
                        });
                }

            })
            .catch((err) => {
                this.setState({ isLoading: false, errorData: err.message });
            });
    }


    onChangeLoadLocationCode = value => {
        getLokationsKode().then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                isLoading: false,
                                LokationsKodeData: responseData
                            })
                        });
                }

            })
            .catch((err) => {
                this.setState({ isLoading: false, errorData: err.message });
            });
    }

    onChangeLoadPlaced = value => {
        getPlaceCode().then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                isLoading: false,
                                PlaceCodeData: responseData
                            })
                        });
                }

            })
            .catch((err) => {
                this.setState({ isLoading: false, errorData: err.message });
            });
    }

    onChangeCommentsToSerialNo(e) {
        let comment = e.target.value;
        let errors = { commentstoSerialNo: '' };
        if (comment.length > 250) {
            errors.commentstoSerialNo = "Should not be more than 250 characters";
        }
        if (!comment.match("^[a-zA-Z0-9 ,.'/|-]*$")) {
            errors.commentstoSerialNo = "Invalid character";
        }
        else {
            this.setState(function (prevState) {
                return {
                    currentAsset: {
                        ...prevState.currentAsset,
                        commentsToSerialNo: comment
                    }

                };
            });
        }
        this.setState({ errors });
    }

    // for autocomplete onchange - to capture user typed input and load SerialNumberData from API
    onChangeInputSerialNo = value => {
        const caseInsensitiveValue = value.toUpperCase()
        getSerialNoForAutocomplete(caseInsensitiveValue).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                isLoading: false,
                                SerialNumberData: responseData
                            })
                        });
                }
                else {
                    this.setState({ noData: true })
                    this.handleInvalidSerialNo();
                    this.cancelAssetData();

                }
            })
            .catch((err) => {
                this.setState({ isLoading: false, errorData: err.message });
            });
    }


    //Below 4 lines helps capture selected item from autocomplete dropdown
    // Below 'if' cancels(removes) all data, if 'x' button s clicked on serial number autocomplete box.
    onChangeCaptureAutComSerienr = (event, value) => {
        this.setState(function (prevState) {
            return {
                currentAsset: {
                    ...prevState.currentAsset,
                    serienr: value
                }

            };

        }, () => {
            if (this.state.currentAsset.serienr == null) {
                this.cancelAssetData();
                this.setState({ validSerialNo: false });
                this.handleInvalidSerialNo()
            }
            else {
                this.searchSerienr()
            }
        });
    }

    cancelAssetData = () => {
        this.setState({ message: "" })
        // this.setState({ currentAsset: {} });
        this.setState(function (prevState) {
            return {
                currentAsset: {
                    ...prevState.currentAsset,
                    varenummer: "",
                    status: "",
                    modificationStatus: "",
                    beskrivelse: "",
                    lokationsKode: "",
                    placed: "",
                    lokationsBeskrivelse: '',
                    sidsteLevel1Point: '',
                    sidsteLevel3Point: '',
                    sidsteLevel1Dato: "",
                    sidsteLevel3Dato: "",
                    overordnetSerienr: "",
                    commentsToSerialNo: ''
                }
            };
        });
    }

    onChangeLokationsKode = (event, value) => {
        this.setState(function (prevState) {
            return {
                currentAsset: {
                    ...prevState.currentAsset,
                    lokationsKode: value
                }
            };
        }, () => {
        });
    }

    onChangeVarenummer = (event, value) => {
        this.setState(function (prevState) {
            return {
                currentAsset: {
                    ...prevState.currentAsset,
                    varenummer: value
                }
            };
        }, () => {

        });
    }

    onChangePlaceCode = (event, value) => {
        this.setState(function (prevState) {
            return {
                currentAsset: {
                    ...prevState.currentAsset,
                    placed: value
                }
            };
        }, () => {

        });
    }

    // To handle update message Validation
    handleUpdateMsgValidation = () => {
        let errors = { serienr: '', commentstoSerialNo: '' };

        if (this.state.message) {
            this.setState({ message: "" })
        }
        this.setState({ errors });
    }

    handleInvalidSerialNo = () => {
        let errors = { validSerialNo: '' };
        if (this.state.noData === true) {
            errors.validSerialNo = 'Not a valid serial number';
        }
        else {
            errors.validSerialNo = '';
        }

        this.setState({ noData: false })
        this.setState({ errors });
    }

    handleEmptyValuesandConflict = () => {
        let errors = { emptyValues: '', serienr: '' };
        if (this.state.currentAsset.varenummer == null || this.state.currentAsset.varenummer == '') {
            errors.emptyValues = 'Item Number cannot be empty';
            this.setState({ conflict: false })
        }
        else if (this.state.currentAsset.lokationsKode == null || this.state.currentAsset.lokationsKode == '') {
            errors.emptyValues = 'Location Code cannot be empty';
            this.setState({ conflict: false })
        }
        else if (this.state.currentAsset.placed == null || this.state.currentAsset.placed == '') {
            errors.emptyValues = 'Placed cannot be empty';
            this.setState({ conflict: false })
        }

        if (this.state.conflict) {
            this.searchSerienr();
            errors.serienr = 'Changes cannot be saved because another user has modified the same asset, Please recheck the current reloaded values and update.';
        }
        this.setState({ conflict: false })
        this.setState({ errors });
    }

    // Returns an asset
    async searchSerienr() {
        this.handleUpdateMsgValidation();
        await getAsset(this.state.currentAsset.serienr).then(
            (response) => {
                if (response.ok) {
                    // loading helps to remove cursor on search field 
                    // So after search, keyboard closes by default on phone
                    this.setState({ isLoading: true })
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                isLoading: false,
                                currentAsset: responseData
                            })
                            // For setting status tooltip on load
                            this.setState({ statusToolTip: this.state.status[this.state.currentAsset.status]['key'] });
                            this.setState({ modificationStatusToolTip: this.state.modificationStatus[this.state.currentAsset.modificationStatus]['key'] });

                            /* For trimming date field */
                            let sidsteLevel1Dato = this.state.currentAsset.sidsteLevel1Dato && this.state.currentAsset.sidsteLevel1Dato.substring(0, 10);
                            let sidsteLevel3Dato = this.state.currentAsset.sidsteLevel3Dato && this.state.currentAsset.sidsteLevel3Dato.substring(0, 10);
                            this.setState(function (prevState) {
                                return {
                                    currentAsset: {
                                        ...prevState.currentAsset,
                                        sidsteLevel1Dato: sidsteLevel1Dato,
                                        sidsteLevel3Dato: sidsteLevel3Dato
                                    }
                                };
                            });
                        });

                    this.setState({ isLoading: false })
                }
                else if (response.status === 401) {
                    this.setState({ isLoading: false })
                    throw new Error("User not authorized");
                }
                else {
                    this.setState({ isLoading: false })
                    this.setState({ noData: true })
                    this.cancelAssetData();
                    this.handleInvalidSerialNo();                 
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false, errorData: err.message });
            });
    }


    //Update data 
    async updateAsset() {

        let errors = { serienr: '' };
        if (this.state.conflict) {
            errors.serienr = '';
        }
        this.setState({ errors });

        await updateAsset(this.state.currentAsset.serienr,
            this.state.currentAsset).then(
                (response) => {
                    if (response.ok) {
                        response.json()
                            .then((responseData) => {
                                this.setState({
                                    isLoading: false,
                                    message: "Asset updated successfully"
                                })
                            });
                        this.searchSerienr() // Adding this line refreshes, so the optimistic concurrency control does not show error when same user updates(same asset) twice.
                    }
                    else if (response.status === 401) {
                        throw new Error("User not authorized");
                    }
                    else {
                        this.setState({ conflict: true })
                        this.handleEmptyValuesandConflict();
                    }
                })
            .catch((err) => {
                this.setState({ isLoading: false, errorData: err.message });
            });
    }

    // To not update when "no" is pressed in comfirm dialog
    dontUpdate() {
        console.log("Do no update")
    }

    render() {

        let { errorData, isLoading } = this.state;
        if (errorData) {
            return (
                <div className="errorData"> {errorData} </div>
            )
        }
        if (isLoading) {
            return (
                <div className="errorData"> Loading... </div>
            )
        }
        const { currentAsset, errors } = this.state;
        let scannedNo;

        // user role is "undefined" if they dont have update rights.
        // user role is "AssetApp.Update" if they have update rights.
        let userRole;
        let roles = this.props.user.account.idTokenClaims?.roles;
        // Enters below 'if' only claims has roles. Claim will have roles only when user have update rights.
        if (roles && roles.length !== 0) {
            userRole = roles.find(item => item === 'AssetApp.Update') || 'Read'
        }

        return (
            <div>
                {currentAsset ? (
                    <div>
                            <Grid container={true} justifyContent='center' alignItems='center'>
                                <Grid item>
                                    <Box mt={1}>
                                        <MuiThemeProvider theme={theme}>
                                            <Autocomplete
                                                id="searchSerialNumber"
                                                noOptionsText="No options found"
                                                value={this.state.currentAsset.serienr}
                                                options={this.state.SerialNumberData}
                                                renderOption={(option) => (
                                                    <Typography class="typography">{option}</Typography>
                                                )}

                                                getOptionLabel={option => option}
                                                onChange={this.onChangeCaptureAutComSerienr}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        this.searchSerienr();
                                                    }
                                                }}
                                                autoHighlight={true}
                                                //clearOnEscape={true}                                                 
                                                className="searchAutocompleteWidth"
                                                renderInput={params => (
                                                    <TextField
                                                        scannedNo={input => { scannedNo = input; }}
                                                        {...params} onChange={e => this.onChangeInputSerialNo(e.target.value)} label="Search by Serial Number" variant={"outlined"}
                                                        size="small" InputLabelProps={{ className: "inputLabel", shrink: true }} fullWidth />
                                                )}
                                            />
                                        </MuiThemeProvider>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box mt={1}>
                                        <EngravedDialog handlerFromParant={this.handleData} />
                                    </Box>
                                </Grid>
                                <Grid item>
                                <Box mt={1}>
                                    <QRScannerDialog handlerFromParant={this.handleData} />
                                </Box>
                                </Grid>
                               
                            {/*  button click opens and sends serial number data to label printer page */}
                            {/*     <Grid item>
                                    <Tooltip title={<span className='toolTip'>Label Printer</span>} placement="top" arrow='true'>
                                        <button id="printLabelButton"
                                            style={{
                                                marginTop: "6px",
                                                height: 35,
                                                width: 60,
                                                backgroundColor: '#79797F', borderColor: 'lightgrey'
                                            }}
                                            type="submit"
                                            className="badge badge-secondary"
                                            onClick={this.labelPrint}
                                        >
                                           <FontAwesomeIcon
                                                style={{
                                                    backgroundColor: '#79797F', borderColor: 'lightgrey',
                                                    color: 'white',
                                                    width: 21,
                                                    height: 21,
                                                }}
                                                icon={faPrint} />
                                        </button>
                                    </Tooltip>
                            </Grid>*/}  {/* until here */}

                            </Grid>

                            <Grid container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                <Grid item>
                                    {errors.serienr != '' && <span className="error" style={{ marginLeft: "15px" }}>{this.state.errors.serienr}</span>}
                                    {errors.emptyValues != '' && <span className="error">{this.state.errors.emptyValues}</span>}
                                    {errors.validSerialNo != '' && <span className="error">{this.state.errors.validSerialNo}</span>}
                                </Grid>
                            </Grid>

                            <Grid className="setSize" container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                <Grid item>
                                    <Box mt={4}>
                                        <Tooltip title={<span className='toolTip'>{this.state.statusToolTip}</span>} placement="top" arrow='true'>
                                            <TextField
                                                id="status"
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                disabled={(userRole == "AssetApp.Update") ? false : true}
                                                size="small"
                                                label="Status"
                                                value={currentAsset.status}
                                                onChange={this.onChangeStatus}
                                                variant="outlined"
                                                className="autocompleteWidth"
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                                select
                                            >
                                                {this.state.status && this.state.status.map((x) => (
                                                    <MenuItem className="statusMenuItem" key={x.key} value={x.id}>
                                                        {x.key}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{this.state.modificationStatusToolTip}</span>} placement="top" arrow='true'>
                                            <TextField
                                                id="modStatus"
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                disabled={true}
                                                size="small"
                                                label="Modification Status"
                                                value={currentAsset.modificationStatus}
                                                onChange={this.onChangeModificationStatus}
                                                variant="outlined"
                                                className="autocompleteWidth"
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                                select
                                                SelectProps={{ IconComponent: () => null }}
                                            >
                                                {this.state.modificationStatus && this.state.modificationStatus.map((y) => (
                                                    <MenuItem key={y.key} value={y.id}>
                                                        {y.key}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                <Grid item>
                                    <Box mt={2}>
                                        <MuiThemeProvider theme={theme}>
                                            <Tooltip title={<span className='toolTip'>{currentAsset.varenummer}</span>} placement="top" arrow='true'>
                                                <Autocomplete
                                                    id="iNumber"
                                                    disabled={(userRole == "AssetApp.Update") ? false : true}
                                                    value={this.state.currentAsset.varenummer}
                                                    options={this.state.VarenummerData}
                                                    renderOption={(option) => (
                                                        <Typography class="typography">{option}</Typography>
                                                    )}
                                                    autoHighlight={true}
                                                    getOptionLabel={option => option}
                                                    onChange={this.onChangeVarenummer}
                                                    className="autocompleteWidth"
                                                    renderInput={params => (
                                                        <DarkerDisabledTextField {...params} onChange={e => this.onChangeLoadVarenummer(e.target.value)} label="Item Number" variant={"outlined"}
                                                            size="small" InputLabelProps={{ className: "inputLabel", shrink: true }} />
                                                    )}
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{currentAsset.beskrivelse}</span>} placement="top" arrow='true'>
                                            <TextField
                                                type="text"
                                                disabled={true}
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                name="itemN0Des"
                                                label="Item Number Desc"
                                                variant="outlined" size="small"
                                                className="textFieldWidth"
                                                id="itemDescription"
                                                value={currentAsset.beskrivelse}
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>




                            </Grid>

                            <Grid className="setSize" container={true} justifyContent='center' alignItems='flex-end' spacing={1}>

                                <Grid item>
                                    <Box mt={2}>
                                        <MuiThemeProvider theme={theme}>
                                            <Tooltip title={<span className='toolTip'>{currentAsset.lokationsKode}</span>} placement="top" arrow='true'>
                                                <Autocomplete
                                                    id="locCode"
                                                    disabled={(userRole == "AssetApp.Update") ? false : true}
                                                    value={this.state.currentAsset.lokationsKode}
                                                    options={this.state.LokationsKodeData}
                                                    renderOption={(option) => (
                                                        <Typography class="typography">{option}</Typography>
                                                    )}
                                                    autoHighlight={true}
                                                    getOptionLabel={option => option}
                                                    onChange={this.onChangeLokationsKode}
                                                    className="autocompleteWidth"
                                                    renderInput={params => (
                                                        <DarkerDisabledTextField {...params} onChange={e => this.onChangeLoadLocationCode(e.target.value)} label="Location Code" variant={"outlined"} size="small"
                                                            InputLabelProps={{ className: "inputLabel", shrink: true }} fullWidth />
                                                    )}
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{currentAsset.lokationsBeskrivelse}</span>} placement="top" arrow='true'>
                                            <TextField
                                                type="text"
                                                disabled={true}
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                label="Location Desc"
                                                variant="outlined"
                                                size="small"
                                                className="textFieldWidth"
                                                id="locationDesc"
                                                value={currentAsset.lokationsBeskrivelse}
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid className="setSize" container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                <Grid item>
                                    <Box mt={2}>
                                        <MuiThemeProvider theme={theme}>
                                            <Tooltip title={<span className='toolTip'>{currentAsset.placed}</span>} placement="top" arrow='true'>
                                                <Autocomplete
                                                    id="placed"
                                                    disabled={(userRole == "AssetApp.Update") ? false : true}
                                                    value={this.state.currentAsset.placed}
                                                    options={placeCodes}
                                                    renderOption={(option) => (
                                                        <Typography class="typography">{option}</Typography>
                                                    )}
                                                    autoHighlight={true}
                                                    getOptionLabel={option => option}
                                                    onChange={this.onChangePlaceCode}
                                                    className="autocompleteWidth"
                                                    renderInput={params => (
                                                        <DarkerDisabledTextField {...params} onChange={e => this.onChangeLoadPlaced(e.target.value)} label="Placed" variant={"outlined"} size="small"
                                                            InputLabelProps={{ className: "inputLabel", shrink: true }} fullWidth />
                                                    )}
                                                />
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </Box>
                                </Grid>

                                <Grid item>

                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{currentAsset.overordnetSerienr}</span>} placement="top" arrow='true'>
                                            <TextField
                                                type="text"
                                                disabled={true}
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                label="Parent Serial Number"
                                                variant="outlined"
                                                size="small"
                                                className="textFieldWidth"
                                                id="parentSerialNumber"
                                                value={currentAsset.overordnetSerienr}
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>

                            </Grid>

                            <Grid className="setSize" container={true} justifyContent='center' alignItems='flex-end' spacing={1}>


                                <Grid item>
                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{currentAsset.sidsteLevel1Dato}</span>} placement="top" arrow='true'>
                                            <TextField
                                                type="text"
                                                disabled={true}
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                label="Last Level1 Date"
                                                variant="outlined"
                                                size="small"
                                                className="textFieldWidth"
                                                id="lastLevel1Dato"
                                                value={currentAsset.sidsteLevel1Dato}
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{currentAsset.sidsteLevel1Point}</span>} placement="top" arrow='true'>
                                            <TextField
                                                type="text"
                                                disabled={true}
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                label="Last Level1 Point"
                                                variant="outlined"
                                                size="small"
                                                className="textFieldWidth"
                                                id="lastLevel1Point"
                                                value={currentAsset.sidsteLevel1Point}
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>



                            </Grid>


                            <Grid className="setSize" container={true} justifyContent='center' alignItems='flex-end' spacing={1}>
                                <Grid item>
                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{currentAsset.sidsteLevel3Dato}</span>} placement="top" arrow='true'>
                                            <TextField
                                                type="text"
                                                disabled={true}
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                label="Last Level3 Date"
                                                variant="outlined"
                                                size="small"
                                                className="textFieldWidth"
                                                id="lastLevel3Dato"
                                                value={currentAsset.sidsteLevel3Dato}
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{currentAsset.sidsteLevel3Point}</span>} placement="top" arrow='true'>
                                            <TextField
                                                type="text"
                                                disabled={true}
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                label="Last Level3 Point"
                                                variant="outlined"
                                                size="small"
                                                className="textFieldWidth"
                                                id="lastLevel3Point"
                                                value={currentAsset.sidsteLevel3Point}
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>

                            </Grid>

                            <Grid className="setSize" container={true} justifyContent='center' alignItems='flex-end' spacing={1}>

                                <Grid>
                                    <Box mt={2}>
                                        <Tooltip title={<span className='toolTip'>{currentAsset.commentsToSerialNo}</span>} placement="top" arrow='true'>
                                            <TextField
                                                id="comment"
                                                disabled={(userRole == "AssetApp.Update") ? false : true}
                                                InputProps={{
                                                    style: { color: userRole == "AssetApp.Update" ? "" : "#33333F" },
                                                }}
                                                type="text"
                                                label="Comments"
                                                variant="outlined"
                                                size="small"
                                                className="commentWidth"
                                                value={currentAsset.commentsToSerialNo}
                                                onChange={this.onChangeCommentsToSerialNo}
                                                InputLabelProps={{ className: "inputLabel", shrink: true }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid>
                                    {errors.commentstoSerialNo != '' && <span className="error">{this.state.errors.commentstoSerialNo}</span>}
                                </Grid>
                            </Grid>


                        <Grid container={true}>
                            <Grid item>
                                <Box mt={3}>
                                    <ConfirmDialog title="Update" desc="Are you sure you want to update this Asset?" no={() => this.dontUpdate()} confirm={this.updateAsset} validationMsg={this.handleUpdateMsgValidation} disabled={(userRole == "AssetApp.Update") ? false : true} />
                                </Box>

                            </Grid>

                            <Grid item>
                                <Box mt={3}>
                                    <p className="updateMessage"><strong>{this.state.message}</strong></p>
                                </Box>
                            </Grid>              
                        </Grid>
                    </div>
                ) : (
                    <div>

                    </div>
                )}
            </div>

        );
    }

}


