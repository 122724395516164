//Azure uses JSON Web Tokens (JWT)
//ID token: A JWT that contains claims that you can use to identify users in your application
//Access token: A JWT that contains claims that you can use to identify the granted permissions to your APIs

import { MsalAuthProvider, LoginType } from 'react-aad-msal';


const config = {

 auth: {
        authority: "https://login.microsoftonline.com/929c3459-c435-41a3-8c2b-708ebbafd9f8", //Directory (tenant) ID Overview blade of App Registration
        clientId: "fb7ee989-2c96-4dd8-a540-36bcce8f4f9e", //Application (client) ID       
        postLogoutRedirectUri: process.env.REACT_APP_POSTLOGOUTREDIRECTURI,
       // navigateToLoginRequestUrl: true
       //redirectUri: process.env.REACT_APP_REDIRECTURI,
        redirectUri: window.location.origin,
    },  

    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },

};

const AuthenticationParameters = {

    scopes: ['https://graph.microsoft.com/.default'],
    prompt: 'select_account',

};

export const authProvider = new MsalAuthProvider(config, AuthenticationParameters, LoginType.Redirect)

export const authFetch = async (url, method, body) => {
    const token = await authProvider.getIdToken();
    //const token = await authProvider.getAccessToken();

    return fetch(url, {
        method: method,
        headers: {
           Authorization: 'Bearer ' + token.idToken.rawIdToken,
           // Authorization: 'Bearer ' + token.accessToken,
            'Content-Type': 'application/json',
            //'Accept': 'application/json' 
        },
        body: method === 'PUT' ? JSON.stringify(body) : undefined
    });
};





