import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import App from './App';
import { authProvider } from './authProvider';
import { AzureAD } from 'react-aad-msal';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <AzureAD provider={authProvider} forceLogin={true}>
            <App />
        </AzureAD>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

