
import config from '../Config';
import { authFetch } from '../authProvider';


// For fetching a single Asset record using search button
export async function getAsset(Serienr) {
    return await authFetch(config.apiBaseUrl + `/api/asset/${Serienr}`, 'GET');
}

// For LokationsKode autocomplete
export async function getLokationsKode() {
    return await authFetch(config.apiBaseUrl + `/api/asset/LokationsKode`);
}

// For Varenummer autocomplete
export async function getVarenummer() {
    return await authFetch(config.apiBaseUrl + `/api/asset/Varenummer`);
}

// For Placed autocomplete
export async function getPlaceCode() {
    return await authFetch(config.apiBaseUrl + `/api/asset/PlaceCode`);
}

// For Updating an Asset
export async function updateAsset(serienr, data) {

    return await authFetch(config.apiBaseUrl + `/api/asset/${serienr}`, 'PUT', data);

}

// For Updating-addremove parent serial number of a child 
export async function AddRemoveParentSerialNoOfChildren(ChildSerialNo, data) {
    return await authFetch(config.apiBaseUrl + `/api/asset/hierarchy/${ChildSerialNo}`, 'PUT', data);
}

// For Updating-add parent serial number of a grand child
export async function AddParentSerialNoOfGrandChildren(GrandChildSerialNo, data) {
    return await authFetch(config.apiBaseUrl + `/api/asset/hierarchyAddGC/${GrandChildSerialNo}`, 'PUT', data);
}

// For Updating-remove parent serial number of a grand child
export async function RemoveParentSerialNoOfGrandChildren(GrandChildSerialNo, data) {
    return await authFetch(config.apiBaseUrl + `/api/asset/hierarchyGC/${GrandChildSerialNo}`, 'PUT', data);
}

//For Serial Number autocompete - returns serialnumbers that matches the search value entered
export async function getSerialNoForAutocomplete(inputText) {
    return await authFetch(config.apiBaseUrl + `/api/asset/serienrAutocomplete/?serialno=${inputText}`);
}

//For Scanner autocomplete - returns serialnumbers that matches the scanned value
export async function getSerialNoForScannerAutocomplete(inputText) {
    return await authFetch(config.apiBaseUrl + `/api/asset/serienrScannerAutocomplete/?serialno=${inputText}`);
}

//For searching hierarchy of Serial Number 
export async function getHierarchy(inputText) {
    return await authFetch(config.apiBaseUrl + `/api/asset/hierarchySearch/?serialno=${inputText}`);

}


//Below for Redress (Label printer):

// For fetching a collection of key value pair for given serial number
export async function getRedress(Serienr) {
    return await authFetch(config.apiBaseUrl + `/api/redress/${Serienr}`, 'GET');
}



 // List of place codes for autocomplete
export const placeCodes = [
    "",
    "PERENCO OFFSHORE",
    "(SIT)SURFACE TEST",
    "1.01",
    "1.02",
    "1.03",
    "1.04",
    "10101",
    "10102",
    "10103",
    "10104",
    "10201",
    "10202",
    "10203",
    "10204",
    "10301",
    "10302",
    "10303",
    "10304",
    "10401",
    "10402",
    "10403",
    "2.01",
    "2.02",
    "2.03",
    "2.04",
    "20101",
    "20102",
    "20103",
    "20104",
    "20201",
    "20202",
    "20203",
    "20204",
    "20301",
    "20302",
    "20303",
    "20304",
    "20401",
    "20402",
    "20403",
    "20404",
    "20501",
    "20502",
    "20503",
    "20504",
    "20601",
    "20602",
    "20603",
    "20604",
    "3.01",
    "3.02",
    "3.03",
    "3.04",
    "30101",
    "30102",
    "30103",
    "30104",
    "30201",
    "30202",
    "30203",
    "30204",
    "30301",
    "30302",
    "30303",
    "30304",
    "30401",
    "30402",
    "30403",
    "30404",
    "30501",
    "30502",
    "30503",
    "30504",
    "30601",
    "30602",
    "30603",
    "30604",
    "4.01",
    "4.02",
    "4.03",
    "4.04",
    "40101",
    "40102",
    "40103",
    "40201",
    "40301",
    "40401",
    "40402",
    "40403",
    "40501",
    "50100",
    "50101",
    "50102",
    "50103",
    "50104",
    "50105",
    "50106",
    "50200",
    "50201",
    "50202",
    "50203",
    "50204",
    "50205",
    "50206",
    "50300",
    "50301",
    "50302",
    "50303",
    "50304",
    "50305",
    "50306",
    "50401",
    "50402",
    "50403",
    "50404",
    "50405",
    "50501",
    "50502",
    "50503",
    "50504",
    "1ST 218 HLSA RENTAL",
    "1ST 318 HLSA RENTAL",
    "2ND 218 HLSA RENTAL",
    "2ND 318 HLSA RENTAL",
    "50.02.00",
    "50.03.00",
    "50.04.00",
    "50.05.00",
    "50.06.00",
    "50.07.00",
    "50.08.00",
    "50.09.00",
    "50.10.00",
    "90.01.01",
    "90.01.02",
    "90.01.03",
    "90.01.04",
    "90.02.01",
    "90.02.02",
    "90.02.03",
    "90.02.04",
    "90.03.01",
    "90.03.02",
    "90.03.03",
    "90.03.04",
    "90.04.00",
    "90.04.01",
    "90.04.02",
    "90.04.04",
    "90.05.00",
    "90.05.01",
    "90.05.04",
    "90.06.00",
    "90.06.01",
    "90.06.03",
    "90.06.04",
    "90.07.00",
    "90.07.02",
    "90.08.01",
    "90.08.02",
    "90.09.00",
    "90.09.02",
    "90.11.04",
    "90.15.02",
    "90.16.00",
    "90.16.01",
    "90.16.03",
    "90.17.00",
    "90.17.02",
    "90.18.00",
    "90.18.01",
    "90.18.02",
    "90.18.03",
    "90.19.00",
    "90.19.01",
    "90.19.02",
    "90.19.03",
    "90.20.00",
    "90.20.01",
    "90.20.02",
    "90.20.03",
    "AA-LOC",
    "AB-LOC DUKHAN",
    "ACADEMY",
    "ACNO",
    "ADCO LOST IN HOLE",
    "ADMA JOB",
    "ADMA LOST IN HOLE",
    "ADNOC OFFSHORE",
    "ADNOC ONSHORE",
    "ADTI ENSCO 70",
    "AKER BP",
    "AKERBP",
    "AKOFS/ SEAFARER",
    "AKSAI",
    "AL-MANSOORI",
    "ALGERIA",
    "ALTA IV",
    "AMELAND WESTGAT",
    "ANCHORAGE",
    "ANGOLA",
    "APACHE",
    "APACHE BERYL ALPHA",
    "APACHE BERYL BRAVO",
    "APACHE FORTIES ALPHA",
    "APACHE FORTIES BRAVO",
    "APACHE FORTIES CHARL",
    "APACHE FORTIES DELTA",
    "APRG",
    "ARCHER NO",
    "ARCTIC LNG 2",
    "ARMADA",
    "ASCO",
    "ASKELADDEN",
    "ASKELADDEN K-4BH",
    "ASKEPOTT",
    "ASKEPOTT. H-9A",
    "ASSEMBLING",
    "ASSIGNED FOR AP10",
    "ASSIGNED FOR BARAM S",
    "ASSIGNED FOR HESS",
    "ASSIGNED FOR INDONES",
    "ASSIGNED FOR THAILAN",
    "ASSIGNED FOR VIETNAM",
    "AURELIAN EAST POZNAN",
    "AUS WORKSHOP",
    "AUS-PNG",
    "AUSIT",
    "AUSTRALIA",
    "AUSTRIA",
    "AWAITING MOB",
    "AVIO",
    "B.G ENSCO 92",
    "BA-LOC",
    "BACCHUS",
    "BAKER",
    "BAKER BIBRA LAKE AU",
    "BAKER NL",
    "BALLINDALLOCH",
    "BANKERS ALBANIA",
    "BATAM",
    "BBMT EXXON AU",
    "BD-LOC HAK10",
    "BEATRICE",
    "BERKUT",
    "BERYL ALPHA",
    "BG ARMADA",
    "BG AWRMADA",
    "BG BLAKE",
    "BG EVEREST",
    "BG INDIA",
    "BG LAF",
    "BG LOMOND",
    "BH WORKSHOP",
    "BHP AUSTRALIA",
    "BID",
    "BID, H-1, H-3",
    "BID, Q-12",
    "BID, Q-14",
    "BIDEFORD",
    "BIDEFORD DOLPH. I-5",
    "BIDEFORD DOLPHIN",
    "BIDEFORD DOLPHIN B-2",
    "BIDEFORD DOLPHIN D-3",
    "BIDEFORD DOLPHIN D-4",
    "BIDEFORD DOLPHIN. H-",
    "BIDEFORD J-2",
    "BLUE ZONE",
    "BNK",
    "BORGLAD D, VAGA R-14",
    "BORGLAND D, J-14",
    "BORGLAND DOLPHIN",
    "BORTEC",
    "BP",
    "BP ANDREW",
    "BP BRUCE",
    "BP CLAIR",
    "BP CLAIR RIDGE",
    "BP DAB",
    "BP DON",
    "BP EXPLORATION",
    "BP FOINAVEN",
    "BP GALAXY 1",
    "BP HARDING",
    "BP LOYAL",
    "BP MACHAR",
    "BP MACHAR SIT",
    "BP MADOES",
    "BP MAGNUS",
    "BP MONAN",
    "BP MONAN SC",
    "BP MONANA",
    "BP MUNGO",
    "BP PBLJ",
    "BP SCHIEHALLION",
    "BP TRANSOCEAN RATHER",
    "BP WELL ENHANCER",
    "BP VORLICH",
    "BP WYTCH FARM",
    "BP. REACHER",
    "BP. ULA A-2B",
    "BPDEEP",
    "BPN ROOM1",
    "BPN WORKSHOP- MEDCO",
    "BPN WORKSHOP- TOTAL2",
    "BPN WORKSHOP-CHEVRON",
    "BPN WORKSHOP-PHE WMO",
    "BPN WORKSHOP-SANTOS",
    "BPN-WORKSHOP",
    "BPN_MAINTENANCE",
    "BRA, A-14",
    "BRA, A-19",
    "BRA, A-23",
    "BRA, A-33",
    "BRAE BRAVO",
    "BRAGE A-27",
    "BRAGE, A-06",
    "BRAGE, A-2",
    "BRAGE, A-23",
    "BRAGE. A-08",
    "BRAGE. A-18",
    "BRAGE. A-21",
    "BRITANNIA HARBOUR",
    "BRUNEI SHELL",
    "BRUNEI SHELL 318",
    "BUNDUQ OFFSHORE",
    "BURNT SHIPMENT",
    "BYFORD DOLPHIN",
    "CA",
    "CA-LOC HAK12",
    "CAIRN RENTAL",
    "CALIBRATION",
    "CALIFORNIA  JOB JUSC",
    "CAMESA",
    "CD-LOC",
    "CENTRICA NL",
    "CHAYVO",
    "CHESS",
    "CHEVRON",
    "CHEVRON ALBA",
    "CHEVRON CAPTAIN",
    "CHEVRON ERSKINE",
    "CHEVRON NL",
    "CHEVRON-THAILAND",
    "CHI",
    "CHINA",
    "CHRYSAOR",
    "CLADHAN",
    "COBRA HAKURYA-10",
    "COMPLETE TOOLS",
    "COMPLETED",
    "COMPLETIONS",
    "CONGO",
    "CONOCO",
    "CONOCO AU HAL",
    "CONOCO AU JDPA",
    "CONOCO PHILLIPS",
    "CONOCO PHILLIPS JUDY",
    "CONOCO VULKAN RD",
    "CONOCOPHILLIPS",
    "CONOCPHILLIPS",
    "CONT-FWC0010",
    "CONWAY",
    "COPHI_BELANAK",
    "COPNO K-07",
    "COPNO K-17",
    "COPNO S-13",
    "COPNO S-18",
    "COPNOWAB",
    "COSL INNOVATOR. N-21",
    "COSL INNOVATOR. ROLV",
    "COSL PIONEER, C-3 AH",
    "COSL PIONER, A7-HT5",
    "COSL PIONER. C-1",
    "COSL PIONER. C-2AH",
    "COSL PIONER. C-4AH",
    "COSL PIONER. V-1",
    "COSL PROMOTER J-12",
    "COSL PROMOTER L-24",
    "COSL PROMOTER P-11",
    "COSL PROMOTER. J-23",
    "COSL PROMOTOR",
    "COSL PROMOTOR I-14",
    "COSL PROMOTOR K-14",
    "COSL PROMOTOR. J-14",
    "CUSTOMS",
    "D&E",
    "D&E EL AREA",
    "DA-LOC ENSCO",
    "DANA",
    "DANA OG",
    "DEA BAKER",
    "DEA DIEKSAND",
    "DEA MITTLEPLATTE",
    "DEA VOLKERSON",
    "DEEP S ATLANTIC, G-4",
    "DEEP SEA ATLANTIC",
    "DEEP SEA ATLANTIC F1",
    "DEEPSEA ABERDEEN",
    "DEEPSEA ATLANTI F-12",
    "DEEPSEA ATLANTI F-14",
    "DEEPSEA ATLANTIC D-2",
    "DEEPSEA ATLANTIC G-3",
    "DEEPSEA ATLANTIC M-4",
    "DEEPSEA ATLANTIC.M-4",
    "DEEPSEA BERGEN R-1",
    "DEEPSEA STAVANGER",
    "DEEPSEA STV B-6",
    "DISPATCH",
    "DISPATCH ROA",
    "DK DONG",
    "DK100",
    "DK12",
    "DONGLE BOX",
    "DOWELL",
    "DOWN FOR PARTS",
    "DRA, A-05",
    "DSA",
    "DSA B-12",
    "DSA E-1",
    "DSA E-3",
    "DSA, 53 S",
    "DSA, F-3 AH",
    "DSA. M-4B",
    "DSA. N-1",
    "DSB",
    "DSB V-1H",
    "DWG",
    "DYNAMIC MEASUREMENT",
    "E-PLUG AS",
    "E.ON RUHRGAS",
    "EA",
    "EA CONNECT",
    "EA-LOC",
    "ECOP-1",
    "ECOP-2",
    "ECOP-3",
    "ECOP-4",
    "ECOP-5",
    "EDVARD GRIEG A-11",
    "EDVARD GRIEG, A-18",
    "EDVARD GRIEG. A-06",
    "EDVARD GRIEG. A-10",
    "EDVARD GRIEG. A-2",
    "EFD INDUCTION A.S",
    "EKO A, A-08",
    "EKO B, B-02",
    "EKO B, B-08",
    "EKO B, B-09",
    "EKO B, B-16",
    "EKO B, B-18",
    "EKO B,B-07",
    "EKO B-03",
    "EKO B-08",
    "EKO B-12",
    "EKO K",
    "EKO T, E-03",
    "EKO T, E-07",
    "EKO T, E-11",
    "EKO X",
    "EKO X, X-06",
    "EKO X-07",
    "EKO X-36",
    "EKO Z-20",
    "EKO, A-22",
    "EKO, K-08",
    "EKO, M-15",
    "EKO, X-01",
    "EKO, X-06",
    "EKO, X-25",
    "EKO, X-40",
    "EKOFISK A-12",
    "EKOFISK A-15",
    "EKOFISK B",
    "EKOFISK B, B-18",
    "EKOFISK B, B-19",
    "EKOFISK B-07",
    "EKOFISK B-16",
    "EKOFISK B-22",
    "EKOFISK B. B-04",
    "EKOFISK K K-07",
    "EKOFISK K, K-08",
    "EKOFISK K, K-5",
    "EKOFISK K-17",
    "EKOFISK K-18",
    "EKOFISK K-23",
    "EKOFISK K-25",
    "EKOFISK K-3",
    "EKOFISK M",
    "EKOFISK M, M-13",
    "EKOFISK M, M-25",
    "EKOFISK M-08",
    "EKOFISK M-10",
    "EKOFISK M-11",
    "EKOFISK M-14",
    "EKOFISK M-15",
    "EKOFISK M-16",
    "EKOFISK M-21",
    "EKOFISK M-22",
    "EKOFISK M-28",
    "EKOFISK TOR, E-14",
    "EKOFISK X",
    "EKOFISK X, X-03",
    "EKOFISK X, X-08",
    "EKOFISK X, X-27",
    "EKOFISK X, X-35",
    "EKOFISK X, X-42",
    "EKOFISK X-05",
    "EKOFISK X-07",
    "EKOFISK X-18",
    "EKOFISK X-19",
    "EKOFISK X-35",
    "EKOFISK X-36",
    "EKOFISK X-40",
    "EKOFISK X-47",
    "EKOFISK X. X-44",
    "EKOFISK Z-10",
    "EKOFISK Z-19",
    "EKOFISK Z-22",
    "EKOFISK Z-25",
    "EKOFISK Z-27",
    "EKOX X-34",
    "EL DEPT. GREEN SHELF",
    "EL DEPT. RTG",
    "EL. REBUILD",
    "ELD, A-17",
    "ELD, A-27",
    "ELDFISK A, A-12",
    "ELDFISK A, A-14",
    "ELDFISK A-21BT2",
    "ELDFISK B-03",
    "ELDFISK B-06",
    "ELDFISK B-09",
    "ELDFISK B-17",
    "ELDFISK B-18",
    "ELDFISK S-04",
    "ELDFISK S-18",
    "ELDFISK S-34",
    "ELDFISK, A-13",
    "ELDFISK, VA-07",
    "ELDFISK. S-09",
    "ELEC. LAB",
    "ELECTRONIC RACK AREA",
    "ELECTRONIC ROOM",
    "ELECTRONIC STORAGE",
    "ELECTRONICS CABINET",
    "ELECTRONICS LAB",
    "ELECTRONICS RACK",
    "ELEKTRO",
    "ELF, VA-05",
    "ELF,B-01",
    "EM CABLES",
    "EMB",
    "EMO M-28",
    "EMP KALILA INDO",
    "ENERGINET",
    "ENGIE",
    "ENI",
    "ENI CAIRO OFFSHORE",
    "ENI IT",
    "ENI JANGKRIK",
    "ENOS",
    "ENQUEST",
    "ENQUEST HEATHER",
    "ENQUEST MAGNUS",
    "ENQUEST THISTLE",
    "ENSCO 100",
    "ENSCO 101",
    "ENSCO 120",
    "ENSCO 192",
    "ENSCO 72",
    "ENSCO 72, TWC-19",
    "ENSCO 80",
    "ENSCO 92",
    "EPLUG",
    "ERB WEST",
    "ERT UK LTD",
    "EWE",
    "EX-FISHING",
    "EXH CALGARY",
    "EXH DENVER",
    "EXH. RACK DK",
    "EXHIBITION OTC US",
    "EXXON",
    "EXXON BRETTORF",
    "EXXON HESTERBERG",
    "EXXON INDO",
    "EXXON MOBIL",
    "EXXON MOBIL SEAWELL",
    "EXXON MOBILE",
    "EXXON SIT",
    "EXXONMOBIL",
    "EXXONMOBILGER",
    "FACTORY",
    "FAIRFIELD DUNLIN",
    "FALCON NIZWA",
    "FOR TRANSPORTATION",
    "FOR WRD BATTERY TRAN",
    "FORTIES ALPHA",
    "FORTIES BRAVO",
    "FORTIES DELTA",
    "FRØ, B-23",
    "G7 M4",
    "GA-LOC",
    "GALAXY III",
    "GDF",
    "GDF - SUEZ",
    "GDF BRAMHAR",
    "GDF PARAGON",
    "GDF SALZWEDEL",
    "GDF SHEZ",
    "GDMC",
    "GEMUSUT 218",
    "GEOFIZYKA",
    "GEOINFORM",
    "GEOPARK",
    "GFA",
    "GFA, A-06A",
    "GFA, A-07",
    "GFA, A-10",
    "GFA, A-12",
    "GFA, A-14",
    "GFA, A-16",
    "GFA, A-17",
    "GFA, A-20",
    "GFA, A-21",
    "GFA, A-22",
    "GFA, A-25",
    "GFA, A-25BT2",
    "GFA, A-28",
    "GFA, A-31",
    "GFA, A-32",
    "GFA, A-33",
    "GFA, A-34",
    "GFA, A-35",
    "GFA, A-36",
    "GFA, A-39",
    "GFA, A-40",
    "GFA, A-41",
    "GFA, A-41A",
    "GFA, A-42",
    "GFA, A-43",
    "GFA, A-45",
    "GFA, A-46",
    "GFA, A-47",
    "GFA, A-48",
    "GFA-18",
    "GFA-24",
    "GFA-37",
    "GFA-44",
    "GFA. A-20",
    "GFB",
    "GFB, B-01",
    "GFB, B-03",
    "GFB, B-04",
    "GFB, B-05",
    "GFB, B-07",
    "GFB, B-09",
    "GFB, B-11",
    "GFB, B-12",
    "GFB, B-14",
    "GFB, B-15",
    "GFB, B-16",
    "GFB, B-17",
    "GFB, B-18",
    "GFB, B-20",
    "GFB, B-21",
    "GFB, B-23",
    "GFB, B-24",
    "GFB, B-25",
    "GFB, B-26",
    "GFB, B-26 AY2",
    "GFB, B-27",
    "GFB, B-28",
    "GFB, B-29",
    "GFB, B-30",
    "GFB, B-31",
    "GFB, B-32",
    "GFB, B-33",
    "GFB, B-34",
    "GFB, B-35",
    "GFB, B-36",
    "GFB, B-37",
    "GFB, B-39",
    "GFB, B-40",
    "GFB, B-41",
    "GFB, B-42",
    "GFB-02",
    "GFB-08",
    "GFB-26AY2",
    "GFC",
    "GFC C-36",
    "GFC, C-01",
    "GFC, C-03",
    "GFC, C-04",
    "GFC, C-06",
    "GFC, C-07",
    "GFC, C-09",
    "GFC, C-10",
    "GFC, C-12",
    "GFC, C-14",
    "GFC, C-16",
    "GFC, C-17",
    "GFC, C-18",
    "GFC, C-19",
    "GFC, C-20",
    "GFC, C-21",
    "GFC, C-22",
    "GFC, C-23",
    "GFC, C-24",
    "GFC, C-27",
    "GFC, C-30",
    "GFC, C-31",
    "GFC, C-32",
    "GFC, C-34",
    "GFC, C-35",
    "GFC, C-38",
    "GFC, C-45",
    "GFC, C-46",
    "GFC-08",
    "GFC-13",
    "GFC-44",
    "GFC-47",
    "GFC. C-08A",
    "GFS/ASKELADDEN G-4AH",
    "GGG",
    "GO WEST",
    "GOPA-10",
    "GORGON",
    "GOWEST ACADEMY",
    "GR.YARMOUTH",
    "GRA, G-18",
    "GRA, G-19",
    "GRA, G-23",
    "GRA, G-32",
    "GRA, G-37",
    "GRA, G-38",
    "GRA.G-21A",
    "GRANE",
    "GRANE G-18",
    "GRANE G-29",
    "GRANE G-4",
    "GRANE G-6",
    "GRANE G-8",
    "GRANE, G-11",
    "GRANE, G-37",
    "GRANE, G-38",
    "GRANE, G-7",
    "GRANE. G-2",
    "GRANE. G-27",
    "GRANE. G-31B",
    "GREEN SECTIONS",
    "GREEN SHELF 01",
    "GREEN SHELF 02",
    "GREEN SHELF 03",
    "GREEN SHELF 04",
    "GREEN SHELF 05",
    "GREEN SHELF 06",
    "GREEN SHELF 07",
    "GREEN SHELF 08",
    "GREEN SHELF 09",
    "GREEN SHELF TOOLBOX",
    "GREEN TAG AREA",
    "GRONINGEN",
    "GUARDIAN",
    "GUINEA",
    "GULLFAKS A-13",
    "GULLFAKS A-37",
    "GULLFAKS A. A-30",
    "GULLFAKS B-22",
    "GULLFAKS C-15",
    "GULLFAKS C-32",
    "GULLFAKS C-52",
    "GULLFAKS C-52B",
    "GULLFAKS C_15",
    "GWE CBC-JKT",
    "GWE-SBY",
    "GYDA A-06",
    "GYDA, A-15",
    "GYDA, A-19",
    "GYDA, A-27",
    "GYDA, A-32D",
    "GYDA. A-08",
    "HALL JKT INDO",
    "HALLIBURTON",
    "HALLIBURTON 1",
    "HALLIBURTON YARD",
    "HBN",
    "HDR, A-07",
    "HDR, A-09",
    "HDR, A-12",
    "HDR, A-15",
    "HDR, A-19",
    "HDR, A-22",
    "HDR, A-23",
    "HDR, A-24",
    "HDR, A-27",
    "HDR, A-30",
    "HDR, A-35",
    "HDR, A-36",
    "HDR, A-39",
    "HDR, A-40",
    "HDR, A-41",
    "HDR, A-42",
    "HDR, A-44",
    "HDR, A-45",
    "HDR, A-47",
    "HDR, A-48",
    "HDR, A-49",
    "HDR, A-54",
    "HDR, A-55",
    "HEIDRUN A-1",
    "HEIDRUN A-33",
    "HEIDRUN A-50",
    "HESP",
    "HESS",
    "HGR",
    "HHI",
    "HIB",
    "HIBISCUS",
    "HIGH TEMP",
    "HLHV VIETNAM",
    "HOCOL",
    "HOFOR",
    "HS ORKA",
    "HULDRA A-11",
    "HULDRA A-5",
    "I. CONSTRUCTOR, I-1H",
    "IC 34-7B2H ISLAND CO",
    "ICO, H-1H",
    "IF, C-2",
    "IF, G-4H",
    "IF, L3-H",
    "IFR",
    "IFR, C2-AHT4",
    "IFR, VIG D-1H",
    "IFR. A-16",
    "IFR. B-11",
    "IFR. J-15HT3",
    "IFR. K-2/E-3/N-3/N-2",
    "IFR. L-21",
    "IFR. N-2",
    "IFR. N-3",
    "IFR. ON BOAT",
    "IFR. STATFJORD E-1",
    "IMC EQUIP BOX M",
    "IMC EQUIP RACK E",
    "IMC TEST EQUIPMENT",
    "IMH LBU",
    "IMPORT RU",
    "IN TRANSIT",
    "IN10 WORKSHOP",
    "INBOUND",
    "INDONESIA",
    "INSPECTION",
    "INVESTIGATION",
    "ISLAN FRONTIER C-1",
    "ISLAN FRONTIER, C-2",
    "ISLAND CONSTRUCTOR",
    "ISLAND FRONTIER G-4",
    "ISLAND FRONTIER H-4H",
    "ISLAND FRONTIER K-1",
    "ISLAND FRONTIER K-4/",
    "ISLAND FRONTIER M-4",
    "ISLAND FRONTIER R-14",
    "ISLAND FRONTIER, SFØ",
    "ISLAND FRONTIER. A-9",
    "ISLAND FRONTIER. D-4",
    "ISLAND FRONTIER. I-4",
    "ISLAND FRONTIER. N-2",
    "ISLAND FRONTIER. N-3",
    "ISLAND WELL R-4",
    "ISLAND WELLSER. D-1",
    "ISLAND WELLSER. R-1",
    "ISLAND WELLSERV A-1",
    "ISLAND WELLSERV F-2",
    "ISLAND WELLSERV F-4",
    "ISLAND WELLSERV M-4",
    "ISLAND WELLSERV. N-3",
    "ISLAND WELLSERVER A-",
    "ISLAND WELLSERVER NB",
    "ISLAND WELLSERVER, G",
    "ISLAND WELLSERVER, Q",
    "ISLANDS WELLSERVER",
    "IST",
    "ITHACA BEATRICE",
    "ITHACA ERSKINE",
    "ITHICA",
    "IVAR AASEN",
    "IVAR AASEN D-14",
    "IVAR AASEN D-5",
    "IWS .G-3",
    "IWS D-2",
    "IWS G-4",
    "IWS H-4",
    "IWS P-2",
    "IWS VIG, D-2",
    "IWS VIG, D-3",
    "IWS, ASG R-3",
    "IWS, H-3-H",
    "IWS,6608-10-F-2 H",
    "IWS.  B-11",
    "IWS. A-3",
    "IWS. AASGARD N-4",
    "IWS. AASGARD P-1",
    "IWS. AASGARD Q-5",
    "IWS. C-1",
    "IWS. D-3",
    "IWS. E-4",
    "IWS. FASTMONTERT",
    "IWS. G-2",
    "IWS. HEIDRUN F-1",
    "IWS. J-1",
    "IWS. K-2",
    "IWS. M-1",
    "IWS. MORVIN B-3",
    "IWS. NORNE P-3",
    "IWS. P-4",
    "IWS. ÅSGAARD P-2",
    "IWS. ÅSGAARD Q-4",
    "JC",
    "JINTAN FIELD",
    "JKT INDONESIA",
    "JKT OFC",
    "JL HYDRAULIC",
    "JOB",
    "JOB- SAGD",
    "JOHAN SVERDRUP",
    "JOHAN SVERDRUP D-13",
    "KANGEAN INDO",
    "KINETIC BASE",
    "KINGFISHER",
    "KISH /KAN DANA",
    "KMN WORKSHOP",
    "KN WAREHOUSE",
    "KUALA LUMPUR",
    "KUMANG-PCSB",
    "KVIT, A-09",
    "KVITEBJØRN A-05",
    "KVITEBJØRN A-1",
    "KVITEBJØRN A-1 T3",
    "KVITEBJØRN A-10",
    "KVITEBJØRN A-12",
    "KVITEBJØRN A-13",
    "KVITEBJØRN A-16",
    "KVITEBJØRN A-2",
    "KVITEBJØRN, A-09",
    "KVITEBJØRN. A-7",
    "LABUAN",
    "LAND OPS SANTOS",
    "LAND OPS-1",
    "LAND OPS-2",
    "LAND OPS-3",
    "LAND OPS-4",
    "LBN WORKSHOP",
    "LEIV EIRIKSON. K-34",
    "LIH",
    "LIH ABQQ",
    "LIH DWG",
    "LIH EA",
    "LOCHRANZA",
    "LOGGING",
    "LOGISTICS AREA",
    "LOST IN HOLE",
    "LOST IN TRANSIT",
    "LSB INDONESIA",
    "LUCAS DRILLING ROMA",
    "LUKOIL PLATFORM",
    "LUN-A",
    "LUNDIN",
    "LUNDIN ALTA 3",
    "LV3-BPN BASE",
    "M RESOLVE VBA-6",
    "M. GIANT, A-2",
    "M. INNOVATOR, B-19",
    "MAERSK COMPLETION",
    "MAERSK GALLANT",
    "MAERSK GIANT, A-3H",
    "MAERSK INNO. VB-07",
    "MAERSK INNOV. S-06",
    "MAERSK INNOV. VB4",
    "MAERSK INNOVATOR",
    "MAERSK INTEGR. A-13",
    "MAERSK INTERCEPTOR",
    "MAERSK INTERPID A-07",
    "MAERSK INTREP.A-03",
    "MAERSK INTREPID A-09",
    "MAERSK INTREPID A-10",
    "MAERSK INTREPID.A-08",
    "MAERSK JANICE",
    "MAERSK NTVL",
    "MAERSK REACHER, A-25",
    "MAERSK RESILIENT",
    "MAGNUS",
    "MAINTENANCE",
    "MAINTENANCE SHOP",
    "MALAYSIA",
    "MAN-1",
    "MAN-2",
    "MAN-3",
    "MANS-1",
    "MANS-2",
    "MARATHON",
    "MARATHON BRAE BRAVO",
    "MARATHON BREA ALPHA",
    "MARATHON OIL POLAND",
    "MARIA",
    "MAURETANIA",
    "MAURITANIA SLB",
    "ME20 SCRAPPED",
    "ME90 SCRAPPED",
    "MECH LAB",
    "MEDCO BELIDA",
    "MEDCO INDONESIA",
    "MEDCO KERISI",
    "MEDCO_BELANAK",
    "MESSOYAKHA",
    "MIA, F-14",
    "MIDMAR MARRIOT 7",
    "MISSING",
    "MISSING 2012",
    "MISSING 2014",
    "MISSING 2016",
    "MISSING 2018",
    "MISSING 2019",
    "MISSING 2020",
    "MISSING 2021",
    "MISSING OFFSHORE",
    "MISSING2015",
    "MND",
    "MOB BOXES",
    "MOB CA10",
    "MOB CA20",
    "MOB CA30",
    "MOB CA60",
    "MOB DK10",
    "MOB FSU10",
    "MOB FSU20",
    "MOB LA10",
    "MOB LA20",
    "MOB LA30",
    "MOB LA40",
    "MOB LA50",
    "MOB LA60",
    "MOB LA70",
    "MOB OTHER",
    "MOB RENTAL",
    "MOB RU10",
    "MOB RU11",
    "MOB RU13",
    "MOB RU20",
    "MOB RU40",
    "MOB RU41",
    "MOB RU70",
    "MOB US120",
    "MOB US130",
    "MOB US20",
    "MOB US30",
    "MOB US40",
    "MOB US50",
    "MOB US80",
    "MOB US85",
    "MOB US90",
    "MOBCAL",
    "MOBUS140",
    "MODIFICATION SHELF",
    "MOLIPAQ",
    "MONARCH",
    "MOSCOW",
    "MSS-1",
    "MUBADALA",
    "MULTILINE YARD TRIAL",
    "MUMBAI",
    "MURCHISON",
    "MURPHY",
    "MURPHY DC 318",
    "MURPHY RCB PACKAGE",
    "MURPHY SPAR",
    "MURPHY-GOPB-09",
    "MURPHY-WELL CUTTER",
    "MXDEMOB",
    "MYANMAR",
    "MÆG, TRYM A1",
    "MÆI, B-18",
    "MÆR, VBA-6",
    "MÆRSK ESBJERG",
    "MÆRSK INNOVATOR",
    "MÆRSK INTEGRAT  B-1",
    "MÆRSK INTEGRATOR",
    "MÆRSK INTREPID SLB-4",
    "NAM",
    "NAM GRK",
    "NAM QARRACK",
    "NAM SPK03",
    "NAM SWIFT 10",
    "NCOC",
    "NEWFIELD",
    "NEXEN",
    "NEXEN BUZZARD",
    "NEXEN ETTRICK",
    "NEXEN G7",
    "NEXEN GOLDEN EAGLE",
    "NEXEN SCOTT",
    "NEXON BUZZARD",
    "NEXON SCOTT",
    "NHAVA",
    "NIGERIA",
    "NINIAN C",
    "NINIAN NORTH",
    "NINIAN SOUTH",
    "NJD, A-05",
    "NJD, A-08",
    "NJD, A-12",
    "NJD, A-14",
    "NJD, A-18",
    "NOBLE",
    "NOC RIG 1",
    "NOC RIG 2",
    "NOC RIG 3",
    "NOC RIG 4",
    "NOC RIGLESS 1",
    "NOISE ROOM",
    "NOLATO",
    "NOR, J-2",
    "NORTH ALWYN",
    "NORTH SLOPE",
    "NORWOOD HOUSE",
    "NOVATEK",
    "NOVY PORT",
    "NWKG",
    "OCEAN NOMAD",
    "OCEAN V",
    "OCEAN V.",
    "OFF SHORE",
    "OFF SHORE BHP",
    "OFF SHORE CHEVRON",
    "OFF SHORE WOODSIDE",
    "OFFSHORE",
    "OFFSHORE - TOTAL",
    "OFFSHORE BHP",
    "OFFSHORE BP",
    "OFFSHORE ENI",
    "OFFSHORE EXXON",
    "OFFSHORE EXXONMOBIL",
    "OFFSHORE OPS-1",
    "OFFSHORE OPS-2",
    "OFFSHORE OPS-3",
    "OFFSHORE SHELL",
    "OFFSHORE STOS",
    "OFFSHORE1",
    "OFFSHORE2",
    "OFFSHORE3",
    "OFFSHORE4",
    "OFFSHORE5",
    "OLD ELEC.",
    "OMAN",
    "OMAN BAKER",
    "OMAN BP JOB",
    "OMAN FALCON JOB",
    "OMAN SLB",
    "OMV",
    "OMV, NZ",
    "ON JOB",
    "ON STANDBY",
    "ONGC",
    "ONGC INT RENTAL",
    "ONGOING TOOLBOX",
    "ONS STAVANGER",
    "OPHYR INDO",
    "ORANGE NASSAU ENERGI",
    "ORANJE",
    "ORLAN",
    "OS CASHIMA",
    "OS IMMORTELLE",
    "OSB",
    "OSB, B-12",
    "OSB, B-28",
    "OSB, B-30",
    "OSB, B-35",
    "OSB, B-44",
    "OSC, C-01",
    "OSC, C-03",
    "OSC, C-04",
    "OSC, C-12",
    "OSC, C-18",
    "OSC, C-20",
    "OSC, C-25",
    "OSE E, E-7",
    "OSE, E-05",
    "OSE, E-9",
    "OSEB ØST. E-03",
    "OSEBERG B, B-33",
    "OSEBERG B-05",
    "OSEBERG B-09",
    "OSEBERG B-1",
    "OSEBERG B-11",
    "OSEBERG B-13",
    "OSEBERG B-18",
    "OSEBERG B-23",
    "OSEBERG B-24",
    "OSEBERG B-27",
    "OSEBERG B-35",
    "OSEBERG B-36",
    "OSEBERG B-38G",
    "OSEBERG B-41",
    "OSEBERG B-46",
    "OSEBERG B-47",
    "OSEBERG B. B-38",
    "OSEBERG C",
    "OSEBERG C, C-2",
    "OSEBERG C-06",
    "OSEBERG C-09",
    "OSEBERG C-10",
    "OSEBERG C-12",
    "OSEBERG C-15",
    "OSEBERG C-17",
    "OSEBERG C-18",
    "OSEBERG C-19",
    "OSEBERG C-22",
    "OSEBERG C-23",
    "OSEBERG C-26",
    "OSEBERG C-5",
    "OSEBERG C. C-08",
    "OSEBERG C. C-14",
    "OSEBERG E-15",
    "OSEBERG S. F-24",
    "OSEBERG SØR F-16",
    "OSEBERG SØR F-17",
    "OSEBERG SØR F-28",
    "OSEBERG SØR F-6",
    "OSEBERG SØR F-7",
    "OSEBERG SØR, F-07",
    "OSEBERG SØR, F-22",
    "OSEBERG SØR, F-8",
    "OSEBERG SØR. F-19",
    "OSEBERG SØR. F-20",
    "OSEBERG SØR. F-21",
    "OSEBERG SØR. F-23",
    "OSEBERG SØR. F-29",
    "OSEBERG SØR. F-5C",
    "OSEBERG SØR. F-9",
    "OSEBERG Ø. E-14C",
    "OSEBERG Ø. E-4",
    "OSEBERG ØST E-14B",
    "OSEBERG ØST, E-04 B",
    "OSEBERG ØST. E-14",
    "OSEBERG ØST. E-5",
    "OSEBERG ØST. E-8",
    "OSEBRG ØST. E-12",
    "OSELVAR A-1",
    "OSS, F-09",
    "OSS, F-12",
    "OSS, F-13",
    "OSS, F-17",
    "OSS, F-18",
    "OSS, F-26",
    "OSS, F-30",
    "OTHER",
    "OXY",
    "PACKAGE PCSB 1",
    "PACKAGE PCSB 2",
    "PACKAGE PCSB NO 3",
    "PACKED",
    "PACKING",
    "PALLET SHELF COMP",
    "PALLET SHELF CONN.",
    "PALLET SHELF HOUSING",
    "PALLET SHELF HYD.",
    "PALLET SHELF MOTOR",
    "PALLET SHELF WCC PAR",
    "PALLET SHELF WCC238",
    "PALLET SHELF WCC318",
    "PALLET SHELF WCC534",
    "PALLET SHELF WDR",
    "PALLET SHELF WK PART",
    "PALLET SHELF WK218",
    "PALLET SHELF WK318",
    "PALLET SHELF WM/WC",
    "PALLET SHELF WRD",
    "PALLET SHELF WS PART",
    "PALLET SHELF WS218",
    "PALLET SHELF WS312",
    "PALLET SHELF WS318",
    "PALLET SHELF WT212",
    "PALLET SHELF WT218",
    "PALLET SHELF WT318",
    "PARTS TO BE CONNECT",
    "PAUL B LLOYD JNR",
    "PBR NS-29",
    "PBR NS-31",
    "PBR NS-32",
    "PBR NS-33",
    "PBR NS-38",
    "PBR NS-39",
    "PBR NS-40",
    "PBR NS-41",
    "PBR NS-42",
    "PBR NS-43",
    "PBR NS-44",
    "PBR NS-47",
    "PBR NS-51",
    "PBR NS-52",
    "PBR SS-70",
    "PBR SS-73",
    "PBR SS-77",
    "PBR SS-79",
    "PBR SS-81",
    "PBR SS-83",
    "PBR SS-88",
    "PCSB - BARAM",
    "PCSB BAYAN",
    "PCSB TUKAU",
    "PCSB-ANGSI",
    "PCSB-ANGSI D",
    "PCSB-ANGSI E",
    "PCSB-ANJUNG KECIL",
    "PCSB-BARAM 318",
    "PCSB-BARONIA",
    "PCSB-BARONIA 318",
    "PCSB-BEKOK",
    "PCSB-BOKOR",
    "PCSB-D18",
    "PCSB-DULANG",
    "PCSB-DUYONG",
    "PCSB-ERB WEST",
    "PCSB-F12",
    "PCSB-F9",
    "PCSB-GAHARU",
    "PCSB-KANOWIT",
    "PCSB-NC3",
    "PCSB-PADANG",
    "PCSB-PULAI",
    "PCSB-RESAK",
    "PCSB-SBO",
    "PCSB-SEMARANG",
    "PCSB-SEPAT",
    "PCSB-SKO",
    "PCSB-SUMANDAK",
    "PCSB-TEMANA",
    "PCSB-ZUHAL",
    "PCVL",
    "PENN",
    "PERENCO",
    "PERENCO C",
    "PERENCO TYNE",
    "PERENCO WYTCH FARM",
    "PERTAMINA EP_POLENG",
    "PERTAMINA ONWJ",
    "PETROFAC",
    "PETROFAC JOHN SHAW",
    "PETROFAC WIL",
    "PETROGAS",
    "PETRONAS KEJT-MADURA",
    "PETRONAS LAMONGAN",
    "PETRORIO",
    "PGNIG",
    "PH ELECTRO",
    "PHE ONWJ",
    "PHE WMO",
    "PHILIPINES",
    "PHKT",
    "PHM",
    "PILTUN",
    "PINDE",
    "PISTONS & INSERTS",
    "PITSTOP",
    "PITSTOP E",
    "PITSTOP M",
    "PNG01",
    "POG SHELL STAGING",
    "POG WORKSHOP",
    "POHOKURA",
    "POLAR PIONEER B-10",
    "POLAR PIONER, J-3 AH",
    "POLAR PIONER. A-31HT",
    "POZA RICA",
    "PREMIER INDO",
    "PREMIER NATUNA GB",
    "PREMIER OIL",
    "PREP WOODSIDE MO",
    "PRODUCTION",
    "PROTRONIX",
    "PROWELL WSHOP",
    "PROVIDENCE RESOURCES",
    "PRUDHOE BAY",
    "PT.PAN INDO",
    "PTTEP MALAYSIA",
    "PTTEP THAILAND",
    "Q-ZONE DISPATCH",
    "Q-ZONE ELECTRONICS",
    "Q-ZONE WORKSHOP",
    "QATAR GAS 1",
    "QP OFFSHORE",
    "QP ONSHORE",
    "QUARANTINE",
    "QUEUE",
    "RABBIT",
    "RACK",
    "RAG",
    "RAHAMAN",
    "RAS GAS 1",
    "RAYMUNDO MEDINA",
    "RBI",
    "RCB TRUCK",
    "READY TO GO",
    "READY TO RETURN",
    "RED RACK",
    "RED TAG AREA",
    "REDRESS",
    "REDRESS LAB",
    "RELIANCE",
    "RENTAL",
    "RENTAL ENI-PETROBEL",
    "RENTAL HLSA",
    "REPAIR",
    "REPSOL",
    "REPSOL ARBROATH",
    "REPSOL AUK",
    "REPSOL KEMAMAN",
    "REPSOL SALTIRE",
    "REPSOL SEAWELL",
    "REPSOL TALISMAN",
    "REPSOL- H4 DRILLING",
    "RESERVED",
    "RESERVED FOR",
    "RESERVED FSU10",
    "RESERVED RU10",
    "RESERVED RU11",
    "RIG OCEAN ROVER",
    "RIG WEST SETIA",
    "RIH, C-19",
    "RINGHORNE",
    "ROC OIL",
    "ROCK ROSE",
    "ROMAG",
    "ROSTOVITSKOE",
    "ROWAN NORWAY",
    "ROWAN NORWAY M-14",
    "ROWAN NORWAY. M-11",
    "ROWAN NORWAY. M-2",
    "ROWAN STAVANGER",
    "ROWAN VIKING",
    "ROWAN VIKING 16/1-A6",
    "ROWAN VIKING A-12",
    "ROWAN VIKING A-13",
    "ROWAN VIKING A-15",
    "ROWAN VIKING A-18",
    "ROWAN VIKING A-19",
    "ROWAN VIKING A-20",
    "ROWAN VIKING, A-7",
    "ROWAN VIKING. A-2",
    "RU41",
    "RUBIALES",
    "RUSSIA LAND",
    "RUSSKOE",
    "RWE GERMANY",
    "RWE MITTELPLATE",
    "RWE MITTELPLATE GMBH",
    "RØD REOL",
    "SABAH SHELL",
    "SAKA PGN SIDAYU",
    "SAKA PGN_UPA",
    "SAKA PGN_UPB",
    "SAKA-SBY",
    "SAMARA",
    "SANTOS OYONG",
    "SAUDI ARAMCO",
    "SCANEX",
    "SCANVISAN",
    "SCARAB-5",
    "SCARAB-8",
    "SCARABEO",
    "SCARABEO 5",
    "SCARABEO 5, LYS A",
    "SCARABEO 5, V-4-H",
    "SCARABEO 5. V-2H",
    "SCARABEO 8",
    "SCARABEO 8. GOLIAT",
    "SCARABEO 8. H-2",
    "SCARABEO 8. KP-5",
    "SCARABEO 8. RMWI-2",
    "SCOTT M",
    "SCRAPPED",
    "SD",
    "SDE, K-4H",
    "SEAFARER/ AKOFS",
    "SEALAND JOB",
    "SEALAND-MOVARAB",
    "SEAWELL",
    "SEDCO 704",
    "SEDCO 711 SHELL",
    "SEDCO 714 TOTAL",
    "SEMICON",
    "SERICA",
    "SERTA",
    "SERVICE",
    "SETCORE",
    "SETCORE CAIRO BASE",
    "SEVKOM",
    "SFA, A-11B",
    "SFA, A-39",
    "SFB. B-12",
    "SFC, C-05",
    "SFC, C-06",
    "SFC, C-37",
    "SFC. B-12",
    "SHELL",
    "SHELL 1",
    "SHELL BITTERN",
    "SHELL BRENT ALPHA",
    "SHELL BRENT DELTA",
    "SHELL CORIB",
    "SHELL CORMORANT ALPH",
    "SHELL CORRIB",
    "SHELL E11",
    "SHELL E6",
    "SHELL F14",
    "SHELL FIELD",
    "SHELL GA4",
    "SHELL GANNET",
    "SHELL GEMUSUT 218",
    "SHELL GOREK",
    "SHELL JW",
    "SHELL KN-114",
    "SHELL MALIKAI",
    "SHELL MERGANSER",
    "SHELL NAM",
    "SHELL NELSON",
    "SHELL OE",
    "SHELL P&A",
    "SHELL SERAI",
    "SHELL SHALLOW CLASTI",
    "SHELL TEAL",
    "SHELL UZMA",
    "SHELL VALARIS 122",
    "SHELL WORK SHOP",
    "SHELL WORKSHOP",
    "SHELL-G102-MALAYSIA",
    "SHELL-GUMUSUT",
    "SHELL-SARAWAK-F23",
    "SHELL-SFRS",
    "SHELL-SRWK-F23-115",
    "SHIPPED",
    "SHOW",
    "SIT",
    "SIT 2",
    "SIT BAKER",
    "SIT HALL",
    "SIT SLB",
    "SKG",
    "SLA, A-04",
    "SLA, A-07",
    "SLA, A-14A",
    "SLA, A-14T2",
    "SLA, A-15",
    "SLA, A-18CT",
    "SLA, A-19",
    "SLA, A-21",
    "SLA, A-23",
    "SLA, A-25",
    "SLB HOLLAND",
    "SLB HOUSTON",
    "SLB YARD BAHRAIN",
    "SLB, B-01",
    "SLB, B-02",
    "SLB, B-08",
    "SLB, B-20",
    "SLB, B-22",
    "SLB-BALIKPAPAN",
    "SLB-CIKARANG",
    "SLEIPNER",
    "SLEIPNER A",
    "SLEIPNER B-4",
    "SLICKLINE",
    "SNA",
    "SNA, P-01",
    "SNA, P-02",
    "SNA, P-03",
    "SNA, P-04",
    "SNA, P-06",
    "SNA, P-07",
    "SNA, P-08",
    "SNA, P-09",
    "SNA, P-10",
    "SNA, P-11",
    "SNA, P-12",
    "SNA, P-13",
    "SNA, P-14",
    "SNA, P-15",
    "SNA, P-16",
    "SNA, P-17",
    "SNA, P-18",
    "SNA, P-19",
    "SNA, P-20",
    "SNA, P-21",
    "SNA, P-22",
    "SNA, P-23",
    "SNA, P-24",
    "SNA, P-26",
    "SNA, P-27",
    "SNA, P-29",
    "SNA, P-30",
    "SNA, P-31",
    "SNA, P-32",
    "SNA, P-33",
    "SNA, P-35",
    "SNA, P-37",
    "SNA, P-38",
    "SNA, P-39",
    "SNA, P-41",
    "SNA, P-42",
    "SNA, P-43",
    "SNA, P-44",
    "SNA, P-45",
    "SNB, A-5AH",
    "SNB, C-1H",
    "SNB, C-4",
    "SNB, C-5",
    "SNB, C-7",
    "SNB, C2AH",
    "SNB, D-1H",
    "SNB, D-2",
    "SNB, D-3 H",
    "SNB, D-4H",
    "SNB, D-4HT2",
    "SNB, D-5",
    "SNB, D-6",
    "SNB, D-7",
    "SNB, D-7H",
    "SNB, H-4",
    "SNB, K-1",
    "SNB, K-4",
    "SNB, K-5",
    "SNB, K-6H",
    "SNB, K-7",
    "SNB, K-8H",
    "SNB, L-2H",
    "SNB. L-3H",
    "SNORRE A, P-25",
    "SNORRE A, P-28",
    "SNORRE A. P-19",
    "SNORRE B, C-6",
    "SNORRE B, C-8 H",
    "SNORRE B. C-2",
    "SNORRE B. D-4",
    "SNORRE B. K-3",
    "SNORRE B. K-7",
    "SOLVAY",
    "SONANGOL_AF10",
    "SONGA D, E-2-H",
    "SONGA D, L1",
    "SONGA DEE",
    "SONGA DEE N-2AH",
    "SONGA DEE N-2H",
    "SONGA DEE O-1H",
    "SONGA DEE, E-4 CH",
    "SONGA DEE. I-4H",
    "SONGA DEE. O-2",
    "SONGA DEE. P-1",
    "SONGA DEE. P-2H",
    "SONGA DEE. P-3",
    "SONGA DELTA",
    "SONGA DELTA H-3AHT2",
    "SONGA DELTA, M-12H",
    "SONGA DELTA. P-3",
    "SONGA ENABLER C-2",
    "SONGA ENABLER, B-8,",
    "SONGA ENDURANCE X-22",
    "SONGA EQUINOX P-13",
    "SONGA EQUINOX Y-24",
    "SONGA EQUINOX, M-14",
    "SONGA EQUINOX. N-13",
    "SONGA EQUINOX. O-13",
    "SONGA EQUINOX. Y-21",
    "SONGA T, G",
    "SONGA T, O-23",
    "SONGA T, Y-23 CY1H",
    "SONGA TRYM B-51",
    "SONGA TRYM, P-13",
    "SOROVSKOE",
    "SOUTH AFRICA",
    "SOUTHWEST CALIBRATIO",
    "SPE HOUSTON",
    "SPIRIT AUDREY",
    "SPIRIT CHESTNUT",
    "SSE",
    "SSE ATTWICK",
    "ST. JOSEPH",
    "STANDBY",
    "STAR ENERGY INDO",
    "STATFJORD A",
    "STATFJORD A-11",
    "STATFJORD A-16",
    "STATFJORD A-25",
    "STATFJORD A-42",
    "STATFJORD B",
    "STATFJORD B, B-09",
    "STATFJORD B, B-30",
    "STATFJORD B-05",
    "STATFJORD B-11",
    "STATFJORD B-18",
    "STATFJORD B-22",
    "STATFJORD B-23",
    "STATFJORD B-24",
    "STATFJORD B-29",
    "STATFJORD C",
    "STATFJORD C-12",
    "STATFJORD C-13",
    "STATFJORD C-16",
    "STATFJORD C-18",
    "STATFJORD C-26",
    "STATFJORD C-27",
    "STATFJORD C-36",
    "STATFJORD C-4B",
    "STATFJORD D-3",
    "STATOIL PEREGRINO A",
    "STATOIL PEREGRINO B",
    "STC C-5",
    "STELLA EKO-PLI",
    "STENA",
    "STENA DON D-6AY1H",
    "STENA DON, J-12H",
    "STOCK",
    "STOCKROOM",
    "STOLEN",
    "STORAG ETZEL",
    "STORAGE",
    "STUCK",
    "SUPERIOR",
    "SUPERIOR BASE",
    "SUPREME",
    "SUZUN",
    "SW KIEL",
    "SWITCH-IT",
    "SYD ARNE. SAN-04",
    "SYNERGY/DANA",
    "TAGUL",
    "TALISMAN",
    "TALISMAN ARBROATH",
    "TALISMAN CHANTER",
    "TALISMAN CLAYMORE",
    "TALISMAN CLYDE",
    "TALISMAN KEMAMAN",
    "TALISMAN ROSS",
    "TALISMAN SALTIRE",
    "TALISMAN SCAPA",
    "TALISMAN TARTAN",
    "TAMBAR",
    "TAMOKAMAI",
    "TAQA BERGERMEER",
    "TAQA EIDER ALPHA",
    "TAQA NETHERLANDS",
    "TAQA NORTH CORMORANT",
    "TAQA TERN",
    "TBR",
    "TDF",
    "TEMANA PLATFORM",
    "TEMPORARY TO MAL",
    "TENGIZ",
    "TEST",
    "TEST AREA",
    "TEST ELECTRONICS",
    "TEST12345",
    "THAILAND PRECISION",
    "THREADING",
    "TIC",
    "TIFFANY",
    "TOMAKOMAI-JAPAN",
    "TOOL RACK",
    "TOOL RACK1",
    "TOOLBOX U-CELL",
    "TOR",
    "TOR. E-18",
    "TORD, K-3",
    "TOS VUNG TAU",
    "TOTAL",
    "TOTAL ALWYN",
    "TOTAL BASE",
    "TOTAL DUNBAR",
    "TOTAL E&P",
    "TOTAL ELGIN",
    "TOTAL ELGIN ROWAN VI",
    "TOTAL EP MD",
    "TOTAL EP_BEKAPAI",
    "TOTAL EP_HANDIL",
    "TOTAL EP_NUBI",
    "TOTAL EP_PECIKO",
    "TOTAL EP_SISI",
    "TOTAL EP_TAMBORA",
    "TOTAL EP_TUNU",
    "TOTAL HOLLAND",
    "TOTAL RENTAL",
    "TOTAL SEAFOX HOLLAND",
    "TOTAL WEST PHONENIX",
    "TRANSIT",
    "TRANSIT - KMN",
    "TRANSIT INDIA",
    "TRANSO.EQUINOX",
    "TRANSOCEAN ARTIC",
    "TRANSOCEAN E. M-13",
    "TRANSOCEAN ENCO. G-2",
    "TRANSOCEAN ENDU",
    "TRANSOCEAN ENDU. E-3",
    "TRANSOCEAN PROSPECT",
    "TRANSOCEAN S. A-2H",
    "TRANSOCEAN SEARCHER",
    "TRANSOCEAN SERC. A1H",
    "TRANSOCEAN SPITSBERG",
    "TRANSOCEAN W.",
    "TRANSOCEAN W. M1-BH1",
    "TRANSOCEAN WINNE B-2",
    "TRANSOCEAN WINNE M2",
    "TRANSOCEAN WINNER B1",
    "TRANSOCEAN WINNER,K-",
    "TREBSA",
    "TRINIDAD",
    "TRIO",
    "TRO, J-14",
    "TRO, K-12",
    "TUNISIA",
    "U-CELL MOTOR",
    "U-CELL NO. 8",
    "U-CELL NO. 9",
    "U-CELL NO.10",
    "U-CELL NO.11",
    "UFA",
    "UGS",
    "ULA A-5",
    "ULA. A-04",
    "UNDER EXPORT",
    "URUCU",
    "US",
    "W.L. GORE",
    "WA",
    "WABSTOCK",
    "WAL A-22",
    "VALAMON B-20",
    "VALEMON",
    "VALEMON B-08",
    "VALEMON B-8",
    "VAR, A-15",
    "VARG A-12",
    "VARG-03",
    "WC",
    "WC 414",
    "WC20",
    "WEATHERFORD",
    "WEATHERFORD 1",
    "WEATHERFORD 2",
    "WEATHERFORD BASE",
    "WEATHERFORD W/S",
    "VEGA R-14BH",
    "WELDING ROOM",
    "WELL 138",
    "WELL ENHANCER",
    "WELLTEC WAB STOCK",
    "WELLVENE UK",
    "VENDOR DISPLAY",
    "WEP",
    "WEP, SLB-01/08",
    "VERMILLION ENERGY",
    "VESLEFRIKK A-09",
    "VESLEFRIKK A-11",
    "VESLEFRIKK A-17",
    "VESLEFRIKK A-20",
    "VESLEFRIKK A-23",
    "VESLEFRIKK A-24",
    "VESLEFRIKK A-2B",
    "VESLEFRIKK, A-2B",
    "WEST ELARA",
    "WEST ELARA B-1",
    "WEST ELARA, B-14",
    "WEST ELARA, B-2",
    "WEST ELARA. B-07",
    "WEST ELARA. B-20",
    "WEST EPSILON A-11",
    "WEST EPSILON, A-12",
    "WEST EPSILON, A-9",
    "WEST HERCULES",
    "WEST LINUS Z-02",
    "WEST LINUS Z-22",
    "WEST LINUS Z-34",
    "WEST LINUS. Z-13",
    "WEST LINUS. Z-15",
    "WEST LINUS. Z-27",
    "WEST LUTONG",
    "WEST PHOENIX",
    "WEST VENTURE D-5AH",
    "WEST VENTURE D-6",
    "WEST VENTURE, J-22",
    "WEST VENTURE. E-6",
    "WEST VENTURE. G-1",
    "VESTIGO",
    "VF A-12",
    "VF, A-02",
    "VF, A-03",
    "VF, A-05",
    "VF, A-07",
    "VF, A-08",
    "VF, A-09AT2",
    "VF, A-10",
    "VF, A-13",
    "VF, A-15",
    "VF, A-19",
    "VF, A-1AY1",
    "VF, A-1AY2",
    "VF, A-2-B",
    "VF, A-21",
    "VF, A-22",
    "VF, A-22B",
    "VF, A-23",
    "VF, A-3C",
    "VFA, A-20",
    "VFA, A-20 A",
    "VFR A-09",
    "WG WORKSHOP",
    "WG-14",
    "WG-15",
    "WG16",
    "WG19",
    "WG2",
    "WG20",
    "WG3",
    "WG4",
    "WG5",
    "VIETNAM",
    "VIETNAM WORKSHOP",
    "VIG, B-2H",
    "VIG, B-3HT2",
    "VIG, C-1H",
    "VIG, C-2",
    "VIG, C-2 AH",
    "VIG, C-4H",
    "VIG, C-5",
    "VIG, C-5H",
    "VIG, C-6H",
    "VIG, D-2AH",
    "VIG, D-2H",
    "VIG, D-3H",
    "VIG, E-1H",
    "VIG, E-2",
    "VIG, E-3H",
    "VIG, F-4H",
    "VIG, G-1",
    "VIG, G-2H",
    "VIG, G-3",
    "VIG, G-4",
    "VIGDIS, C-1",
    "WINDINGS",
    "WINTERSHALL",
    "WINTERSHALL ALPHA",
    "WINTERSHALL NOBLE G",
    "WINTERSHALL WINGATE",
    "WIRELINE",
    "VIS, A-17",
    "VIS, A-30",
    "VIS, A-8 BH",
    "VIS, V-4H",
    "VISSER & SMIT",
    "VISUND A-18",
    "VISUND A-32",
    "VISUND. A-23",
    "VISUND. C-4AH",
    "WM TEST AREA",
    "VNG",
    "VOLBURG",
    "WOP",
    "WORKBENCH 00",
    "WORKBENCH 01",
    "WORKBENCH 02",
    "WORKBENCH 03",
    "WORKBENCH 04",
    "WORKBENCH 05",
    "WORKBENCH 06",
    "WORKBENCH 07",
    "WORKBENCH 08",
    "WORKBENCH 09",
    "WORKBENCH 10",
    "WORKBENCH FI",
    "WORKSHOP",
    "WORKSHOP BRUNEI",
    "WORKSHOP KEMAMAN",
    "WORKSHOP SECIAL CABI",
    "WORKSHOP UK20",
    "WORKSHOP WHITE CONTA",
    "WORKSOP",
    "WPS, D-1",
    "WST SAUDI",
    "WT 8",
    "WT1",
    "WT27",
    "WT38",
    "WT6",
    "WT68",
    "WT69",
    "WT71",
    "WUNOT",
    "WUNOT-TRANSIT",
    "WVENT, H-3",
    "WVENT, Z-2H",
    "WVENT,N-22",
    "VÅR ENERGI",
    "YARD",
    "YELLOW CAB",
    "ZADCO DRILLING BASE",
    "ZADCO JOB",
    "ZEGAR",
];
