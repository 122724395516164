
var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken.accessToken);
        }
    });

    return client;
}

// this method will return the user details of provided userId in the attribute

export async function getUserDetails(userId, accessToken) {

    const client = getAuthenticatedClient(accessToken);
    const user = await client.api('/' + userId).get();
   // const user = await client.api('/users/{id | userPrincipalName}') .get();    // to get email or userID
    
    return user;
}

