import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import './Hierarchy.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export class ConfirmDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {          
        };
    }

    handleToggle = () => {
        this.props.validationMsg();
        this.setState({
            open: !this.state.open,
        })
    }

    // When yes is pressed
    wrapperFunctionForYes = () => {
        this.props.confirm();
        this.handleToggle();
    }

    //when no is pressed
    wrapperFunctionForNo = () => {
        this.props.no();
        this.handleToggle();
    }

   
    render() {
        const { open } = this.state   

        return (
            <div>
                <Button style={{
                    textTransform: 'capitalize',              
                    fontSize: 13,                 
                    height: 36,
                    fontWeight: '900px !important',
                    color: 'white',
                    fontFamily: "Arial",
                    // backgroundColor: '{this.props.bcolor}'
                    backgroundColor: '#79797F', borderColor: 'lightgrey'
                }} variant="outlined" disabled={this.props.disabled} onClick={this.handleToggle}>
                    <FontAwesomeIcon
                        style={{
                            backgroundColor: '#79797F', borderColor: 'lightgrey',
                            color: 'white',
                            width: 21,
                            height: 21,
                        }}
                        icon={this.props.icon} />
                    {this.props.title}

                    
                </Button>

                <Dialog fullWidth open={open} maxWidth="xs" onClose={this.handleToggle}>
                    <DialogTitle id="customized-dialog-title">
                        <span style={{
                            backgroundColor: "#FFFFFF",
                            color: '#79797F',
                            fontFamily: "Arial",
                            fontSize: 14,
                        }}> {this.props.desc} </span>
                    </DialogTitle>
                    <DialogActions>
                        <Button style={{
                            textTransform: 'capitalize',
                            width: 70,
                            height: 30,
                            fontSize: 14,
                            fontFamily: "Arial",
                            color: 'white',
                            backgroundColor: '#2da55d',
                        }} onClick={this.wrapperFunctionForYes}>
                            Yes
                        </Button>
                        <Button style={{
                            textTransform: 'capitalize',
                            width: 70,
                            height: 30,
                            fontSize: 14,
                            fontFamily: "Arial",
                            color: 'white',
                            backgroundColor: '#2da55d',   // 65b565 previously used
                        }} onClick={this.wrapperFunctionForNo}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}








