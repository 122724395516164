import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { authProvider } from './authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { getUserDetails } from './services/GraphService';
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect, Route, Link } from "react-router-dom";
import { Asset } from './components/Asset';
import { Hierarchy } from './components/Hierarchy';
import { SignIn } from './components/SignIn';
import logo from './images/Welltec_Logo_EG1_RGB.png';
import IdleTimer from 'react-idle-timer';
import TimeoutDialog from './components/TimeoutDialog';
import { LabelPrinter } from './components/LabelPrinter';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            user: null,
            isLoadingDone: false,
            showModal: false
        };
        this.idleTimer = null
        this.logoutTimer = null;

        var user = authProvider.getAccount();
        if (user) {
            this.getMyProfile();
        }
    }

    async login() { 
        await authProvider.login();
        await this.getMyProfile();
    }

    async getMyProfile() {

        try {
            var accessToken = await authProvider.getAccessToken();
            if (accessToken) {
                this.setState({
                    isLoading: false,
                    user: await getUserDetails("me", accessToken),
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    // For disabling links for 5s on load
    componentDidMount() {
        setTimeout(() => this.setState({ isLoadingDone: true }), 1000);
    }

    //Below methods for Timeout Session
    onIdle = () => {
        this.togglePopup();
        this.logoutTimer = setTimeout(() => {
            //this.handleLogout();
            authProvider.logout();
        }, 1000 * 5 * 1); // 5 seconds before redirection
    }

    togglePopup = () => {
        this.setState(prevState => ({ showModal: !prevState.showModal }));
    }

    handleStayLoggedIn = () => {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = null;
        }
        this.idleTimer.reset();
        this.togglePopup();
    }

    render() {

        let { isLoadingDone } = this.state;
        const { showModal } = this.state;


        return (

            <div>
          
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    stopOnIdle={true}
                    onIdle={this.onIdle}
                    timeout={1000 * 900 * 1} /> {/* set for 15 mins (900) */}

                <TimeoutDialog
                    showModal={showModal}
                    togglePopup={this.togglePopup}
                    handleStayLoggedIn={this.handleStayLoggedIn}
                    handleLogOut={this.handleLogOut}
                />

                <nav
                    className="navbar navbar-expand navbar-dark">
                    <a href="/" className="navbar-brand">
                        <img src={logo} alt="logo" className="photo" />
                    </a>
                    <a className="nav-item">
                        <Link to='/' className="nav-link" aria-haspopup="true">
                            Home
                        </Link>
                    </a>
                    <a className="nav-item1">
                        <Link to='/asset' className="nav-link" aria-haspopup="true">
                            Asset
                        </Link>
                    </a>
                    <a className="nav-item2">
                        {/*<Link to={isLoadingDone ? "/hierarchy" : "#"} */}
                        <Link to="/hierarchy" className="nav-link" aria-haspopup="true">
                            Hierarchy
                        </Link>
                    </a>
                    <a className="navbar-nav mr-auto"> </a>

                    <a className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <AzureAD provider={authProvider} forceLogin={true}>
                            {
                                ({ login, logout, authenticationState, error, accountInfo }) => {
                                    switch (authenticationState) {
                                        case AuthenticationState.Authenticated:
                                            return (
                                                <p className="navbar-right">
                                                    <button class="badge badge-light"
                                                        id="signInOut"
                                                        onClick={logout}>Sign Out</button>
                                                </p>
                                            );
                                        case AuthenticationState.Unauthenticated:
                                            return (
                                                <div>
                                                    <p>
                                                        <button className="badge badge-secondary"
                                                            id="signInOut"
                                                            onClick={login}>Sign In</button>
                                                    </p>
                                                </div>
                                            );
                                        case AuthenticationState.InProgress:
                                            return (<p>Authenticating...</p>);
                                    }
                                }
                            }
                        </AzureAD>

                    </a>
                </nav>

                {/* below 4 lines added for AzureAD - to get accountInfo like username and email */}
                <AzureAD provider={authProvider} forceLogin={true}>
                    {
                        ({ login, logout, authenticationState, error, accountInfo }) => {
                            return (

                                <Layout>
                                    {                                     
                                        <React.Fragment>
                                            {/* <Redirect exact from ="/" to="/home" />*/}
                                            <Route exact path="/"
                                                render={(props) =>
                                                    <SignIn {...props} user={accountInfo} />
                                                } />
                                            <Route exact path="/home"
                                                render={(props) =>
                                                    <SignIn {...props} user={accountInfo} />
                                                } >
                                            </Route>
                                            <Route exact path="/asset"
                                                render={(props) =>
                                                    <Asset {...props} user={accountInfo} />
                                                } />

                                            <Route exact path="/hierarchy"
                                                render={(props) =>
                                                    <Hierarchy {...props} user={accountInfo} />
                                                } />
                                            <Route exact path="/labelPrinter"
                                                render={(props) =>
                                                    <LabelPrinter {...props} user={accountInfo} />
                                                } />
                                        </React.Fragment>
                                    }
                                </Layout>

                            );
                        }
                    }
                </AzureAD>


            </div>

        );
    }
}




