import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const TimeoutDialog = ({ showModal, togglePopup, handleStayLoggedIn, handleLogOut }) => {
    return <Modal isOpen={showModal} toggle={togglePopup} keyboard={false} backdrop="static">
        <ModalHeader>Session Timeout!</ModalHeader>
        <ModalBody>
       Your session has expired due to inactivity 
        </ModalBody>
        {/*<ModalFooter>
            <Button style={{
                fontFamily: "Helvetica Neue Regular !important",
                color: 'white',
                backgroundColor: '#2da55d',                   
            }} onClick={handleStayLoggedIn}>Stay Logged In</Button>

            <form action="https://login.microsoftonline.com/929c3459-c435-41a3-8c2b-708ebbafd9f8/oauth2/v2.0/logout">
                <Button type="submit" style={{               
                    fontFamily: "Helvetica Neue Regular !important",
                    color: 'white',
                    backgroundColor: '#2da55d',            
                }}>Log Out</Button>
            </form>        
        </ModalFooter> */}
    </Modal>
}

export default TimeoutDialog;