import React, { useState } from "react";
import Webcam from "react-webcam";
import { Grid } from '@material-ui/core';
import { computerVision } from './AzureComVision';
import CircularProgress from '@material-ui/core/CircularProgress';

// called from image capture function - converts base64 encoded data url to blob
const makeBlob = function (dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: contentType });
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
}

const EngravedScanner = ({ handlerFromParant }) => {
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);
    const [response, setResponse] = useState(null);
    // change to let helped empty 'text' for retry
    const [text, setText] = useState(null);
    const [processing, setProcessing] = React.useState(false);

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const videoConstraints = {
        facingMode: FACING_MODE_ENVIRONMENT
    };

    const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

    const handleClick = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_ENVIRONMENT
                    ? FACING_MODE_USER
                    : FACING_MODE_ENVIRONMENT
        );
    }, []);


    const capture = React.useCallback(() => {
        setProcessing(true)
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        computerVision(makeBlob(imageSrc)).then(res => {
            var temp = res.text.readResults.map(page => page.lines.map(line => line.text).join(" ")).join("\n")
            var words = temp.split(' ');

            function findLongestWord(temp) {
                var words = temp.split(' ');
                var longest = '';

                for (var i = 0; i < words.length; i++) {
                    if (words[i].length > longest.length && words[i].indexOf("/") === -1) {
                        longest = words[i];
                    }
                }
                return longest;
            }

            if ("WELLTEC".includes(words[0].toUpperCase()) && words.length == 2) {
                //checks if first word is 'welltec' and if there are 2 words, if so display 2nd word as result
                var result = words[1]
            }
            else if (words.length == 1) {
                //if there is just serialNo in image
                var result = temp
            }
            else if (("ITEC".includes(words[0].toUpperCase()) || "ILTEC".includes(words[0].toUpperCase()) || "LITEC".includes(words[0].toUpperCase()) || "ELITEC".includes(words[0].toUpperCase()) || "WOLLTEC".includes(words[0].toUpperCase())) && words.length == 2) {
                var result = words[1]
            }
            else if (("WELLTEC".includes(words[0].toUpperCase()) || "ITEC".includes(words[0].toUpperCase()) || "ILTEC".includes(words[0].toUpperCase()) || "LITEC".includes(words[0].toUpperCase()) || "ELITEC".includes(words[0].toUpperCase()) || "WOLLTEC".includes(words[0].toUpperCase())) && words.length > 2) {
                //checks if first word is welltec and if there is 3rd word in image, if so display 3rd word as result
                var result = words[2]
            }
            // added for removing dates.
            else if (words.length > 1) {
                //If more than one word, display the longest word which does not contain "/")
                var LongestWord = findLongestWord(temp);
                var result = LongestWord
            }

            const text = result
            setText(text)
            setResponse(res)
            handlerFromParant(text);
            setProcessing(false)
        })
    }, [webcamRef, setImgSrc]);


    const resetStates = () => {
        setImgSrc(null)
        handlerFromParant(null);
        setResponse(null)
    }

    if (processing) {
        return <div className="processingWindow">
            <CircularProgress color="success" /></div>
    }

    return (
        <>
            <div>
                {!imgSrc && <div style={{ color: "grey", fontFamily: "Helvetica Neue Regular" }}>
                    <>            
                        <div className="parentvideostream">
                            <Webcam className="webcam"
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    ...videoConstraints,
                                    facingMode
                                }}
                            />
                        </div>
                    </>
                
                    <Grid container={true} justifyContent='center' alignItems='center' spacing={1}>
                        <button style={{
                            textTransform: 'capitalize',
                            width: 140,
                            height: 30,
                            //width: '50%',
                           // height: '30%',
                            fontSize: 13,
                            fontFamily: "Arial",
                            color: 'white',
                            backgroundColor: '#79797F',
                            borderColor: 'lightgrey',
                        }} onClick={handleClick}>Switch Camera</button>

                        <button
                            style={{
                                textTransform: 'capitalize',
                                width: 140,
                                height: 30,
                               // width: '49%',
                               // height: '30%',
                                fontSize: 13,
                                fontFamily: "Arial",
                                color: 'white',
                                backgroundColor: '#79797F',
                                borderColor: 'lightgrey',
                            }} onClick={capture}> Capture Image </button>
                    </Grid>
                    <br />
                </div>}

                {imgSrc && <div className="parentvideostream">
                    <img className="webcam"
                        src={imgSrc}
                    />
                </div>}

                {response && <div>
                    <Grid container={true} justifyContent='center' alignItems='center' spacing={1}>
                        <button style={{
                            textTransform: 'capitalize',
                            width: '171px',
                            height: 30,
                            fontSize: 13,
                            fontFamily: "Arial",
                            color: 'white',
                            backgroundColor: '#79797F',
                            borderColor: 'lightgrey',
                            marginTop: '0px',
                            marginBottom: '11px'
                        }}
                            onClick={resetStates}>Retry</button>
                    </Grid>
                </div>}
            </div>
        </>
    );
};

export default EngravedScanner
