import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Popper from '@material-ui/core/Popper';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import QrReader from 'react-qr-reader'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid } from '@material-ui/core';
import { getAsset, getSerialNoForScannerAutocomplete, getSerialNoForAutocomplete } from '../services/LibraryService';
import './Hierarchy.css';
import Tooltip from '@material-ui/core/Tooltip';

import ScannerIcon from '@material-ui/icons/Scanner';
import EngravedScanner from './EngravedScanner';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export class EngravedDialog extends Component {

    constructor(props) {
        super(props);
        console.log("Props in Input :", props);
        this.submitHandler = this.submitHandler.bind(this);

        this.state = {
            result: '',
            open: false,
            SerialNumberData: [],
            errors: { validSerialNo: '' }, 
            noData: false, // No correct match from API, suggestions returned
            notSuggestionsAvialable: false, // when no suggestions returned
            notSuggestionsAvialable2: false, 

            // below two lines to show(open dropdown by default) suggestions dropdown, if scanned result is incorrect
            openAutoCom: false,
            inputValue: "",

            currentAsset: {
                isLoading: true,
                serienr: '',
            }
        };
    }


   // Open autocomplete dropdown, only if input len is greater than 1 (not when empty)
    handleOpen = () => {
        if (this.state.inputValue.length > 1) {
            this.setState({
                openAutoCom: true,

            })        }
    };

    handleInputChange = (event, newInputValue) => {
        this.setState({
            inputValue: newInputValue,

        })
        if (newInputValue.length > 0) {
            this.setState({
                openAutoCom: true,

            })
        } else {
            this.setState({
                openAutoCom: false,

            })
        }
    };


     // to open and close the dialog
    handleToggle = () => {
        this.setState({
            open: !this.state.open,

        })

        // to empty the automplete box (remove previous search suggestions), while entering
        // 'if' is used to empty autocomplete only when entering scanner, not while exit
        if (this.state.open === false) {
            this.setState({
                result: '',
                SerialNumberData: []
            })
        }

         // below lines to disable error message, if exists from previous search
        //this.setState({ noData: false })
        this.handleInvalidSerialNo();


    }

    // If data from scanner, set 'result' and perform search. Else(if retry was clicked) set result as empty string.
    handleScan = data => {

        // to remove previous search suggestions(empty autocomplete)
        this.setState({
            SerialNumberData: []
        })

        this.handleInvalidSerialNo();
        if (data) {
            this.setState({
                result: data
            })
            // To show scanner dropdown 
            //this.onChangeScannerSerialNo(data)
            this.searchSerienr();
        }
        else {
            this.setState({
                result: ''
            })
        }
    }

    // submit or pass 'serial number' to asset and hierarchy page
    submitHandler(evt) {
        evt.preventDefault();
        // pass the input field value to the event handler - passed as a prop by the parent     
        this.props.handlerFromParant(this.state.result);
        this.setState({
            result: '',
        });
    }

    // Autocomplete onchange - to capture user typed input and load SerialNumberData from API
    onChangeInputSerialNo = value => {    
        const caseInsensitiveValue = value.toUpperCase()
        getSerialNoForAutocomplete(caseInsensitiveValue).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                isLoading: false,
                                SerialNumberData: responseData

                            })
                        });
                }
                else {
                    // to remove previous search suggestions, after removing all characters from automplete box
                    this.setState({
                        SerialNumberData: []
                    })
                }

            })
    }

    // Scanner autocomplete onchange - to capture scanned input and load SerialNumberData from API
    onChangeScannerSerialNo = value => {
        const caseInsensitiveValue = value.toUpperCase()
        getSerialNoForScannerAutocomplete(caseInsensitiveValue).then(
            (response) => {
                if (response.ok) {
                    response.json()
                        .then((responseData) => {
                            this.setState({
                                isLoading: false,
                                SerialNumberData: responseData
                            })
                            // For displaying, invalid select from suggestions' error msg
                            this.setState({ isLoading: false })
                            this.setState({ noData: true })
                            this.handleInvalidSerialNo();
                        });
                }
                // For displaying 'More than two characters are incorrect, retry scanning' msg, if no suggestions returned
                else {
                    this.setState({ isLoading: false })
                    this.setState({ noData: false })
                    this.setState({ notSuggestionsAvialable: true })
                    this.setState({ notSuggestionsAvialable2: true })
                    this.handleNoSuggestions();                 
                }

            })
    }

    //To set selected item from autocomplete dropdown to 'serienr'
    onChangeCaptureAutComSerienr = (event, value) => {

        // to remove previous search suggestions, when "x" was clicked.
        if (value === null) {
            this.setState({
                SerialNumberData: []
            })
        }

        this.handleInvalidSerialNo();
        this.setState(function (prevState) {
            return {
                currentAsset: {
                    ...prevState.currentAsset,
                    serienr: value
                }
            };

        }, () => {

        });
        this.setState({
            result: value
        });
    }

    // Check if asset exists - If not, display error msg 
    async searchSerienr() {
        await getAsset(this.state.result).then(
            (response) => {
                if (response.ok) {
                    // loading helps to remove cursor on search field 
                    // So after search, keyboard closes by default on phone
                    this.setState({ isLoading: true })
                    response.json()
                        .then((responseData) => {
                            console.log("Success, valid serial number")
                            // to close dropdown, when scanned result is correct
                            this.setState({ openAutoCom: false })

                        });

                    this.setState({ isLoading: false })
                }
                else if (response.status === 401) {
                    this.setState({ isLoading: false })
                    throw new Error("User not authorized");
                }
                else {
                    // To show scanner dropdown 
                    this.onChangeScannerSerialNo(this.state.result)                
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false, errorData: err.message });
            });
    }

    //For displaying error as 'Not a valid serialNo'  
    handleInvalidSerialNo = () => {
        let errors = { validSerialNo: '' };
        if (this.state.noData === true) {
            errors.validSerialNo = 'Invalid, select from suggestions';
        }
        else {
            errors.validSerialNo = '';
        }

        this.setState({ noData: false })
        this.setState({ errors });
    }
    
    handleNoSuggestions = () => {
        let errors = { notSuggestionsAvialable: '' };

        if (this.state.notSuggestionsAvialable === true) {
            errors.notSuggestionsAvialable = 'More than two characters are incorrect';
            errors.notSuggestionsAvialable2 = 'Please retry scanning';
        }
        else {
            errors.notSuggestionsAvialable = '';
            errors.notSuggestionsAvialable2 = '';
        }

        this.setState({ notSuggestionsAvialable: false })
        this.setState({ notSuggestionsAvialable2: false })
        this.setState({ errors });
    }


    // on keydoown of textfield near onchange of autocomplete
    keyPress(event) {
        if (event.keyCode = 'enter') {
            event.target.blur();
            console.log("enter")
        }
    }

    render() {
        const { open } = this.state
        const { errors } = this.state;
        const textStyle = {
            justifyContent: 'center',
            fontFamily: "Helvetica Neue Bold",
            fontSize: 15,
            "text-align": "right",
        }

        return (
            <div>
                <Button style={{
                    backgroundColor: "#79797F",
                    width: 30,
                    height: 36,
                    borderColor: 'lightgrey'

                }} variant="outlined" disabled={this.props.disabled} onClick={this.handleToggle}>

                    <Tooltip title={<span style={{ fontSize: "11px", color: "white" }}>Engraving Scanner</span>} placement = "top" arrow='true'>
                    <ScannerIcon
                        style={{
                            color: 'white',
                            paddingBottom: '7px',                        
                            width: 32,
                            height: 32,
                        }}
                        />
                    </Tooltip>

                </Button>

                <Dialog fullWidth open={open} maxWidth="sm" onClose={this.handleToggle}>
                    <DialogTitle style={{
                        backgroundColor: "#2da55d"
                    }}  id="customized-dialog-title" onClose={this.handleToggle}>
                        <span style={{
                            fontFamily: "Arial",
                            fontSize: 13,
                            color: "white",
                        }}>Scan serial number </span>
                    </DialogTitle>

                    <DialogContent>
                        <Grid container={true} justifyContent='center' alignItems='center'>
                            <Grid Item>
                                {errors.validSerialNo != '' && <span className="error">{this.state.errors.validSerialNo}</span>}
                                {errors.notSuggestionsAvialable != '' && <span className="error">{this.state.errors.notSuggestionsAvialable}</span>}
                            </Grid>
                        </Grid>

                        <Grid container={true} justifyContent='center' alignItems='center'>
                            <Grid Item>                           
                                {errors.notSuggestionsAvialable2 != '' && <span className="error">{this.state.errors.notSuggestionsAvialable2}</span>}
                            </Grid>
                        </Grid>

                        <Grid container={true} justifyContent='center' alignItems='center' spacing={2}>
                            <Grid Item>
                                <Box mt={3}>

                                    <EngravedScanner handlerFromParant={this.handleScan} />
                                    </Box>
                            </Grid>
                        </Grid>

                        <form onSubmit={this.submitHandler}>
                                <p style={textStyle}>                          
                                <Grid container={true} justifyContent='center' alignItems='center' spacing={2}>
                                    <Grid Item>
                                        <Box mt={2}>
                                            <Autocomplete
                                                freeSolo                                          
                                                open={this.state.openAutoCom}
                                                onOpen={this.handleOpen}
                                                onClose={() => this.setState({
                                                    openAutoCom: false,

                                                }) }
                                                inputValue={this.state.inputValue}
                                                onInputChange={this.handleInputChange}

                                            value={this.state.result}
                                            options={this.state.SerialNumberData}
                                            autoHighlight={true}
                                            renderOption={(option) => (
                                                <Typography class="typography">{option}</Typography>
                                            )}
                                            getOptionLabel={option => option}
                                            onChange={this.onChangeCaptureAutComSerienr}
                                            style={{
                                                width: 170,
                                            }}
                                            renderInput={params => (
                                                <TextField {...params}  onChange={e => this.onChangeInputSerialNo(e.target.value)} label="Serial Number" variant={"outlined"}
                                                    size="small" InputLabelProps={{ style: { fontSize: 14, color: '#33333C', fontFamily: "Arial" }, shrink: true }} fullWidth />
                                            )}
                                        />
                                        </Box>
                                    </Grid>                               
                                </Grid>

                                {/* <div className="submitPositioning"> */}
                                <Button style={{
                                    textTransform: 'capitalize',
                                    width: 70,
                                    height: 30,
                                    fontSize: 13,
                                    fontFamily: "Arial",
                                    color: 'white',
                                    backgroundColor: '#2da55d',  
                                    borderColor: 'red !important',                             
                                    marginTop: '15px',
                                }}
                                        type="submit"
                                        onClick={this.handleToggle}>Submit</Button>                          
                                </p>                      
                         </form>
                      
                    </DialogContent>

                </Dialog>
            </div>
        );
    }

}